<article
  class="toast aw-2-b--radius-l aw-2-sh--xl"
  [@toastAnimation]
  [style.top.px]="top"
  [style.zIndex]="zIndex"
  [ngClass]="colorClass"
>
  <span *ngIf="isHover || isTouchDevice" class="toast__hide-button">
    <aw-close-button
      (click)="onClose()"
    />
  </span>

  <section class="toast__content aw-2-p--m aw-2-pos--relative">
    <p class="aw-2-txt--bold aw-2-txt--center aw-2-txt--night">{{ translations.toastTitle }}</p>

    <div class="toast__content__actions aw-2-mt--m">
      <span class="toast__content__actions--toggle">
        <aw-switch-button
          [size]="Size.s"
          (change)="toggleOpenLinksInDesktop()"
        />

        <p class="aw-2-txt--steel aw-2-txt--body-small aw-2-txt--bold">{{ translations.alwaysOpenInDesktop }}</p>
      </span>

      <aw-button
        [size]="Size.s"
        [text]="translations.openInDesktop"
        (click)="openDeepLink()"
      />
    </div>
  </section>
</article>
