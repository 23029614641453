import { differenceInCalendarDays, isSameDay } from '@awork/_shared/functions/date-fns-wrappers'
import { dateToNumeric, getLocalDateFromUTCString, isDateWithinRange } from '@awork/_shared/functions/date-operations'

export enum AbsenceProviders {
  personio = 'personio'
}

export interface IAbsence {
  id: string
  createdOn?: Date
  updatedOn?: Date
  userId: string
  startOn: Date
  endOn: Date
  description: string
  isReadOnly: boolean
  type: 'holiday' | 'absence' | 'workspace-absence'
  englishDescription: string
  externalProvider?: AbsenceProviders
  isHalfDayOnStart?: boolean
  isHalfDayOnEnd?: boolean
  laneOrder?: number
  autoLaneOrder?: number
}

export class Absence implements IAbsence {
  id: string
  createdOn?: Date
  updatedOn?: Date
  userId: string
  startOn: Date
  endOn: Date
  description: string
  isReadOnly: boolean
  type: 'holiday' | 'absence' | 'workspace-absence'
  englishDescription: string
  externalProvider?: AbsenceProviders
  isHalfDayOnEnd?: boolean
  isHalfDayOnStart?: boolean
  laneOrder?: number
  autoLaneOrder?: number

  constructor(data: any = null) {
    if (data) {
      Object.assign(this, data)
    }

    this.laneOrder = 0
    this.autoLaneOrder = 0
  }

  get dueOn(): Date {
    const correctedDate = new Date(this.endOn)
    correctedDate.setHours(0, 0, 0, 0)
    return correctedDate
  }
  set dueOn(value: Date) {
    this.endOn = value
  }

  get duration(): number {
    // If the absence have half start and end days, only count the full days in between
    if (this.isHalfDayOnStart && this.isHalfDayOnEnd) {
      return differenceInCalendarDays(this.endOn, this.startOn)
    }

    // If the absence have 1 half day, we need to add 0.5 day to the duration
    if (this.isHalfDayOnEnd || this.isHalfDayOnStart) {
      return differenceInCalendarDays(this.endOn, this.startOn) + 0.5
    }

    return differenceInCalendarDays(this.endOn, this.startOn) + 1
  }

  static mapAbsence(absence: IAbsence): Absence {
    const mappedAbsence = new Absence(absence)

    // map the UTC dates back to assume they are local date
    if (mappedAbsence.startOn && mappedAbsence.endOn) {
      mappedAbsence.startOn = getLocalDateFromUTCString(mappedAbsence.startOn)
      mappedAbsence.endOn = getLocalDateFromUTCString(mappedAbsence.endOn)
    }
    return mappedAbsence
  }

  /**
   * Check if absence is for the entire day
   * @param {Date} date
   * @returns {boolean}
   */
  isFullDayAbsense(date: Date): boolean {
    const { absenceStartDate, absenceEndDate, isMultiDayAbsence } = this.getAbsenceDateInfo()
    const isDateInAbsenceRange =
      !this.isSameAbsenceDay(date, absenceStartDate) && !this.isSameAbsenceDay(date, absenceEndDate)
    const isOneFullDayAbsence = !this.isHalfDayOnEnd && !this.isHalfDayOnStart && !isMultiDayAbsence
    const startsMultiDayAbsenceWithoutHalf =
      !this.isHalfDayOnStart && this.isSameAbsenceDay(date, absenceStartDate) && isMultiDayAbsence
    const endsMultiDayAbsenceWithoutHalf =
      !this.isHalfDayOnEnd && this.isSameAbsenceDay(date, absenceEndDate) && isMultiDayAbsence

    return (
      isOneFullDayAbsence || startsMultiDayAbsenceWithoutHalf || endsMultiDayAbsenceWithoutHalf || isDateInAbsenceRange
    )
  }

  /**
   * Check if the absence is for the first half of the day
   * @param {Date} date
   * @returns {boolean}
   */
  isHalfDayAbsenceStart(date: Date): boolean {
    const { absenceStartDate, absenceEndDate, isMultiDayAbsence } = this.getAbsenceDateInfo()
    const isOneDayAbsenceOnStart =
      !isMultiDayAbsence && this.isHalfDayOnStart && this.isSameAbsenceDay(date, absenceStartDate)
    const endsMultiDayAbsenceWithHalfDay =
      isMultiDayAbsence && this.isHalfDayOnEnd && this.isSameAbsenceDay(date, absenceEndDate)

    return isOneDayAbsenceOnStart || endsMultiDayAbsenceWithHalfDay
  }

  /**
   * Check if the absence is for the last part of the day
   * @param {Date} date
   * @returns {boolean}
   */
  isHalfDayAbsenceEnd(date: Date): boolean {
    const { absenceStartDate, absenceEndDate, isMultiDayAbsence } = this.getAbsenceDateInfo()
    const isOneDayAbsenceOnEnd =
      !isMultiDayAbsence && this.isHalfDayOnEnd && this.isSameAbsenceDay(date, absenceEndDate)
    const startsMultiDayAbsenceWithHalfDay =
      isMultiDayAbsence && this.isHalfDayOnStart && this.isSameAbsenceDay(date, absenceStartDate)

    return isOneDayAbsenceOnEnd || startsMultiDayAbsenceWithHalfDay
  }

  /**
   * Check if the absence is fully absent on the day
   * @param {Date} date
   * @returns {boolean}
   */
  isFullyAbsentOnDay(date: Date): boolean {
    const isDateInAbsenceRange = isDateWithinRange(date, this.startOn, this.endOn)
    const isFullDayAbsence = !this.isHalfAbsentOnDay(date)

    return isDateInAbsenceRange && isFullDayAbsence
  }

  /**
   * Check if the absence is half absent on the day
   * @param {Date} date
   * @returns {boolean}
   */
  isHalfAbsentOnDay(date: Date): boolean {
    const isHalfDayStart = isSameDay(this.startOn, date) && this.isHalfDayOnStart
    const isHalfDayEnd = isSameDay(this.endOn, date) && this.isHalfDayOnEnd

    return isHalfDayStart || isHalfDayEnd
  }

  /**
   * Maps an absence to an absence per day which contains calculated properties
   * for a specific absence in a calendar day
   * @returns {AbsencePerDay}
   */
  mapToAbsencesPerDay(date: Date, hasMultipleAbsences: boolean): AbsencePerDay {
    return new AbsencePerDay({
      ...this,
      day: date,
      isFullDay: this.isFullDayAbsense(date) || hasMultipleAbsences,
      isHalfDayEnd: this.isHalfDayAbsenceEnd(date),
      isHalfDayStart: this.isHalfDayAbsenceStart(date)
    })
  }

  /**
   * Find all the absences that overlap the absence
   */
  findOverlappingAbsences(absences: Absence[]): Absence[] {
    return absences?.filter(
      abs =>
        this.id !== abs.id &&
        !abs.isReadOnly &&
        (isDateWithinRange(abs.startOn, this.startOn, this.endOn) ||
          isDateWithinRange(abs.endOn, this.startOn, this.endOn))
    )
  }

  /**
   * Merge absence with overlapped ones
   */
  mergeAbsences(overlappedAbsences: Absence[]): void {
    // get the startOn of the earliest overlapped absence
    const earliestOverlappedAbsence = overlappedAbsences.reduce((prev, curr) => {
      return prev.startOn.getTime() < curr.startOn.getTime() ? prev : curr
    })
    // get the endOn of the latest overlapped absence
    const latestOverlappedAbsence = overlappedAbsences.reduce((prev, curr) => {
      return prev.startOn.getTime() > curr.startOn.getTime() ? prev : curr
    })

    // Look for the absences previous to the current absence
    if (this.endOn > earliestOverlappedAbsence.endOn) {
      if (this.startOn <= earliestOverlappedAbsence.startOn) {
        const isEarliestSingleDayAbsence = this.isSameAbsenceDay(
          earliestOverlappedAbsence.startOn,
          earliestOverlappedAbsence.endOn
        )
        const isEarliestHalfDayAbsence =
          earliestOverlappedAbsence.isHalfDayOnEnd || earliestOverlappedAbsence.isHalfDayOnStart
        const isEarliestCovered =
          earliestOverlappedAbsence.startOn > this.startOn && earliestOverlappedAbsence.endOn > this.startOn

        this.isHalfDayOnStart =
          (isEarliestSingleDayAbsence && isEarliestHalfDayAbsence && !latestOverlappedAbsence.isHalfDayOnStart) ||
          isEarliestCovered
            ? this.isHalfDayOnStart
            : false
      } else {
        this.isHalfDayOnStart = earliestOverlappedAbsence.isHalfDayOnStart
      }
    }
    this.startOn = this.startOn > earliestOverlappedAbsence.startOn ? earliestOverlappedAbsence.startOn : this.startOn

    // Look for the absences after the current absence
    if (this.startOn < latestOverlappedAbsence.startOn) {
      if (this.endOn >= latestOverlappedAbsence.endOn) {
        const isLatestSingleDayAbsence = this.isSameAbsenceDay(
          latestOverlappedAbsence.startOn,
          latestOverlappedAbsence.endOn
        )
        const isLatestHalfDayAbsence =
          latestOverlappedAbsence.isHalfDayOnEnd || latestOverlappedAbsence.isHalfDayOnStart
        const isLatestCovered =
          latestOverlappedAbsence.startOn < this.endOn && latestOverlappedAbsence.endOn < this.endOn

        this.isHalfDayOnEnd =
          (isLatestSingleDayAbsence && isLatestHalfDayAbsence && !latestOverlappedAbsence.isHalfDayOnEnd) ||
          isLatestCovered
            ? this.isHalfDayOnEnd
            : false
      } else {
        this.isHalfDayOnEnd = latestOverlappedAbsence.isHalfDayOnEnd
      }
    }
    this.endOn = this.endOn > latestOverlappedAbsence.endOn ? this.endOn : latestOverlappedAbsence.endOn
  }

  /**
   * Compares two absence dates without the time part
   * @param {Date} date1
   * @param {Date} date2
   * @returns {boolean}
   */
  private isSameAbsenceDay(date1: Date, date2: Date): boolean {
    return dateToNumeric(date1) === dateToNumeric(date2)
  }

  /**
   * Gets the start and end date info from the absence without the time part
   * @returns {{ absenceStartDate: Date, absenceEndDate: Date, isMultiDayAbsence: boolean }}
   */
  private getAbsenceDateInfo(): { absenceStartDate: Date; absenceEndDate: Date; isMultiDayAbsence: boolean } {
    return {
      absenceStartDate: this.startOn,
      absenceEndDate: this.endOn,
      isMultiDayAbsence: this.startOn < this.endOn
    }
  }
}

export class AbsencePerDay extends Absence {
  day: Date
  isFullDay: boolean
  isHalfDayEnd: boolean
  isHalfDayStart: boolean

  constructor(data: any = null) {
    super(data)
  }
}
