export enum FeatureRestrictions {
  Autopilot = 'autopilot',
  PrivateProjects = 'privateprojects',
  Teams = 'teams',
  Personio = 'personio',
  PlannerWorkload = 'plannerWorkload',
  TimeTracking = 'timeTracking',
  TimeTrackingDisabled = 'timeTrackingDisabled',
  AdvancedPermissions = 'advancedPermissions',
  TimeTrackingSettings = 'timeTrackingSettings',
  CustomSubdomain = 'customSubdomain',
  EnterpriseIntegrations = 'enterpriseIntegrations',
  DataImport = 'dataImport',
  ConnectSetting = 'connectSetting',
  CustomFields = 'customFields'
}

export const teamFeatureRestrictions = [
  FeatureRestrictions.Autopilot,
  FeatureRestrictions.PrivateProjects,
  FeatureRestrictions.Teams,
  FeatureRestrictions.Personio,
  FeatureRestrictions.PlannerWorkload,
  FeatureRestrictions.TimeTracking,
  FeatureRestrictions.AdvancedPermissions,
  FeatureRestrictions.TimeTrackingSettings,
  FeatureRestrictions.CustomFields
]

export const businessFeatureRestrictions = [
  FeatureRestrictions.CustomSubdomain,
  FeatureRestrictions.EnterpriseIntegrations,
  FeatureRestrictions.TimeTrackingDisabled
]

export const connectFeatureRestrictions = [
  ...businessFeatureRestrictions,
  FeatureRestrictions.ConnectSetting,
  FeatureRestrictions.DataImport
]
