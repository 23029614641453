import { Component, Input, OnInit } from '@angular/core'
import { Notification } from '@awork/framework/models/notification.model'
import { User } from '@awork/features/user/models/user.model'
import { NgIf, NgClass, NgFor } from '@angular/common'
import { UserAvatarComponent } from '../../../../features/user/components/user-avatar/user-avatar.component'

import { NotificationEvents } from '@awork/framework/models/notification.types'

@Component({
  selector: 'aw-notification-avatar',
  templateUrl: './notification-avatar.component.html',
  styleUrls: ['./notification-avatar.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, NgFor, UserAvatarComponent]
})
export class NotificationAvatarComponent implements OnInit {
  @Input() notification: Notification

  users: User[] = []

  ngOnInit(): void {
    if (this.notification) {
      // Get the unique users of the sub-notifications (only for reminders)
      if (this.notification.notifications.length > 0 && this.notification.type === 'reminder') {
        this.notification.notifications.forEach(notification => {
          const notificationUser =
            notification.event === NotificationEvents.Birthday
              ? notification.userInNotification
              : notification.responsibleUser

          // && !this.users.some(user => user.id === notificationUser.id)
          if (notificationUser) {
            this.users.push(notificationUser)
          }
        })
      }

      // If the array is empty (single notification) get the user from the notification
      if (this.users.length === 0) {
        if (this.notification.responsibleUser || this.notification.userInNotification) {
          this.users = [
            this.notification.event === NotificationEvents.Birthday
              ? this.notification.userInNotification
              : this.notification.responsibleUser
          ]
        }
      }
    }
  }

  /**
   * Determines the class for positioning multiple users avatars
   * @returns {string}
   */
  getPositionClass(): string {
    const className = 'multi '
    switch (true) {
      case this.users.length > 3:
        return className + 'multi-4'
      case this.users.length > 1 && this.users.length <= 3:
        return className + 'multi-' + this.users.length
    }
    return null
  }
}
