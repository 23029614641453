import { CloseButtonComponent } from '../../icon-buttons/close-button/close-button.component'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core'
import { animate, keyframes, style, transition, trigger } from '@angular/animations'
import { WithGlobals } from '../../../classes/with-globals'
import { FileUpload, FileUploadStatus } from '@awork/_shared/models/file-upload.model'
import { Router } from '@angular/router'
import { Notification } from '@awork/framework/models/notification.model'
import { BehaviorSubject } from 'rxjs'
import { BrowserService } from '@awork/_shared/services/browser-service/browser.service'
import { PrintService } from '../../../services/print-service/print.service'
import { FileUploadProgressComponent } from '../../files/file-upload-progress/file-upload-progress.component'
import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common'
import { ButtonComponent } from '../../buttons/button/button.component'
import { NotificationAvatarComponent } from '../../misc/notification-avatar/notification-avatar.component'
import { Size } from '@awork/_shared/types/size'
import { Color } from '@awork/_shared/types/color'
import { ToastAction } from './types'
import { ToastOptions } from '../../../services/toast-service/types'
import { ButtonColor } from '../../buttons/button/types'

import { NotificationEvents } from '@awork/framework/models/notification.types'

type FigureType = 'image' | 'icon' | 'avatar' | 'emoji'

@Component({
  selector: 'aw-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('toastAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(-100%)', opacity: '0' }),
        animate(
          '.2s ease-out',
          keyframes([
            style({ transform: 'translateY(-25%)', opacity: '0', offset: 0.75 }),
            style({ transform: 'translateY(0%)', opacity: '1', offset: 1 })
          ])
        )
      ])
    ])
  ],
  standalone: true,
  imports: [
    NgClass,
    NgIf,
    CloseButtonComponent,
    NgSwitch,
    NgSwitchCase,
    NotificationAvatarComponent,
    NgFor,
    FileUploadProgressComponent,
    ButtonComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent extends WithGlobals implements OnInit {
  @Input() top = 10
  @Input() zIndex = 10000
  @Input() fileUploads: FileUpload[] = []
  @Input() options: ToastOptions = {}
  @Input() message: string

  @Output() actionTriggered: EventEmitter<ToastAction> = new EventEmitter<ToastAction>()

  @ViewChild('wrapper') wrapper: ElementRef
  @ViewChild('contentWrapper') contentWrapper: ElementRef

  timeout: number
  isAnimating = true
  isHover = false
  hoverState = new BehaviorSubject<boolean>(false)
  isTouchDevice: boolean
  showActionButton = false

  // shown template values / settings
  template: 'default' | 'upload' = 'default'
  figureType: FigureType
  type: string
  icon: string
  colorClass: string
  buttonColor: ButtonColor

  protected readonly Size = Size
  protected readonly Color = Color

  private cdr = inject(ChangeDetectorRef)

  constructor(
    public el: ElementRef,
    protected router: Router,
    protected browserService: BrowserService,
    public printService: PrintService
  ) {
    super()
  }

  ngOnInit(): void {
    this.isTouchDevice = this.browserService.isTouchDevice()
    this.initToastProperties()
  }

  /**
   * Initializes the toats properties
   */
  initToastProperties(): void {
    this.template = this.getTemplate()
    this.type = this.getTypeName()
    this.figureType = this.getFigureType()
    this.icon = this.getIcon()
    this.colorClass = this.getColorClass()
    this.buttonColor = this.getButtonColor()
    this.showActionButton = this.hasToastAction()

    this.setNotificationText()
  }

  /**
   * Set isAnimating to false after the animation is done
   */
  protected animationDone(): void {
    this.isAnimating = false
  }

  /**
   * Action button clicked. Emits event with string 'action'
   */
  private action(): void {
    this.actionTriggered.emit({ action: 'action' })
  }

  /**
   * Close button clicked. Emits event with string 'close'
   * @param {Event} event - the click event
   */
  protected close(event?: Event): void {
    if (event) {
      event.stopPropagation()
    }

    this.actionTriggered.emit({ action: 'close' })
  }

  /**
   * Executes the action of the notification
   * @param {Event} event
   * @param {Notification} notification
   */
  protected executeAction(event: Event, notification?: Notification): void {
    event.stopPropagation()

    // Check for action function
    if (this.options && this.options.action) {
      this.options.context ? this.options.action(this.options.context) : this.options.action()
      this.close()
      return
    }
    // Check for notification navigation and mark the notification as seen
    else if (
      notification &&
      notification.navigation &&
      (notification.groupingType === 'no-group' || notification.type !== 'reminder')
    ) {
      this.router.navigate(notification.navigation)
    }

    if (
      this.options &&
      this.options.type !== 'fileUpload' &&
      (!this.isNotificationGroup() || (notification && notification.groupingType === 'no-group'))
    ) {
      this.close()
    }

    this.action()
  }

  /**
   * Determines the displayed text in case of events and reminders
   */
  private setNotificationText(): void {
    if ((this.options.type !== 'event' && this.options.type !== 'reminder') || !this.options.notification) {
      return
    }

    this.options.title =
      this.options.type === 'reminder' && this.options.notification?.notifications.length
        ? this.options.notification.groupDescription
        : this.options.notification.description

    this.options.subtitle =
      this.options.notification.text && this.options.notification.detail
        ? `${this.options.notification.text} ${this.options.notification.detail}`
        : this.options.notification.text || this.options.notification.detail
  }

  @HostListener('mouseenter')
  onHover(): void {
    this.isHover = true
    this.hoverState.next(this.isHover)
  }

  @HostListener('mouseleave')
  onBlur(): void {
    this.isHover = false

    // Wait 3 seconds to emit the hover state
    // To prevent hiding the toast immediately
    setTimeout(() => this.hoverState.next(this.isHover), 3000)
  }

  // ###########  Template & Dsiplaying Setup  ###########

  /**
   * Determines if the toast is displaying a notification group
   * @returns {boolean}
   */
  isNotificationGroup(): boolean {
    return (
      this.options.notification?.type === 'reminder' &&
      this.options.notification?.notifications.length > 0 &&
      this.options.notification?.event !== 'birthday'
    )
  }

  /**
   * Gets the type of figure it will be rendered in the toast
   * @returns {FigureType}
   */
  private getFigureType(): FigureType {
    if (!this.options) {
      return null
    }

    const isBirthdayNotification = this.options.type === 'event' && this.options.notification?.event === 'birthday'

    if (isBirthdayNotification) {
      return 'avatar'
    } else if (this.options.imageSrc) {
      return 'image'
    } else if (this.options.emoji) {
      return 'emoji'
    } else {
      return 'icon'
    }
  }

  /**
   * Gets the toasts type name
   */
  private getTypeName(): string {
    const translations = q.translations.ToastComponent

    const types = [
      {
        type: 'info',
        name: translations.type.info
      },
      {
        type: 'action',
        name: translations.type.action
      },
      {
        type: 'error',
        name: translations.type.error
      },
      {
        type: 'event',
        name: translations.type.event
      },
      {
        type: 'reminder',
        name: translations.type.reminder
      },
      {
        type: 'confirmation',
        name: translations.type.confirmation
      },
      {
        type: 'message',
        name: translations.type.message
      },
      {
        type: 'fileUpload',
        name: translations.type.fileUpload
      },
      {
        type: 'thankYou',
        name: translations.type.thankYou
      }
    ]

    const foundType = types.find(richType => this.options.type === richType.type)
    return foundType ? foundType.name : ''
  }

  /**
   * Gets the template based on the type
   * @returns {'default' | 'upload'}
   */
  private getTemplate(): 'default' | 'upload' {
    switch (this.options.type) {
      case 'fileUpload':
        return 'upload'
      default:
        return 'default'
    }
  }

  /**
   * Returns the icon based on the type
   * @returns {string}
   */
  private getIcon(): string {
    switch (this.options.type) {
      case 'error':
        return 'clear'
      case 'confirmation':
        return 'done'
      case 'action':
        return 'flash_on'
      case 'reminder':
        return 'alarm'
      case 'info':
      case 'message':
        return 'info_outline'
      case 'event':
        return this.options.notification?.icon || 'info_outline'
      case 'thankYou':
        return 'thumb_up'
      default:
        return 'error_outline'
    }
  }

  /**
   * Returns the color class based on the type
   * @returns {string}
   */
  private getColorClass(): string {
    switch (this.options.type) {
      case 'error':
        return 'red'
      case 'reminder':
        return 'blue'
      case 'confirmation':
        return 'green'
      case 'action':
        return 'purple'
      case 'thankYou':
        return 'blue'
      default:
        return 'blue'
    }
  }

  /**
   * Returns the color class for the action button based on the type
   * @returns {ButtonColor}
   */
  private getButtonColor(): ButtonColor {
    switch (this.options.type) {
      case 'error':
        return Color.Red
      case 'action':
        return Color.Purple
      default:
        return Color.Blue
    }
  }

  // ########### FILE UPLOAD ##############

  /**
   * Tracks the FileUploads by the tempId
   * @param {number} index
   * @param {FileUpload} fileUpload
   */
  trackFileUploads(index: number, fileUpload: FileUpload): string {
    return fileUpload.trackId
  }

  /**
   * Push the FileUpload if is not already in the array
   * @param {FileUpload} fileUpload
   */
  addFileUpload(fileUpload: FileUpload): void {
    const fileUploadIndex = this.fileUploads.findIndex(
      existingFileUpload => existingFileUpload.trackId === fileUpload.trackId
    )

    if (fileUploadIndex !== -1) {
      this.fileUploads[fileUploadIndex] = new FileUpload(fileUpload)
    } else {
      this.fileUploads.push(fileUpload)
    }

    // Clear the timeout to reset the closing timer
    if (this.timeout) {
      window.clearTimeout(this.timeout)
    }

    // Close the toast if every FileUpload is not uploading
    if (this.fileUploads.every(upload => upload.status !== FileUploadStatus.Uploading)) {
      let timeout = 2000

      // If some file upload failed, rise the timeout to let the user decide if retry
      if (this.fileUploads.some(upload => upload.status === FileUploadStatus.Error)) {
        timeout = 10000
      }

      this.timeout = window.setTimeout(() => this.close(), timeout)
    }

    this.cdr.markForCheck()
  }

  /**
   * Determines if the notification has an action
   * @returns {boolean}
   */
  private hasToastAction(): boolean {
    return (
      this.options &&
      (!!this.options?.action || // Toast has an action
        (this.options?.notification && // Toast has a notification
          ((this.options.notification.navigation && // Notification has navigation
            // Notification is reminder or is ungrouped
            (this.options.notification.type !== 'reminder' || this.options.notification.notifications.length === 0)) ||
            // Notification event is export available
            this.options.notification.event === NotificationEvents.ExportAvailable)))
    )
  }

  /**
   * Triggers change detection
   * This function is needed because the ToastService updates some inputs (e.g.: Update positioning)
   */
  detectChanges(): void {
    this.cdr.markForCheck()
  }
}
