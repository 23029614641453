import { Component, ElementRef, OnDestroy, OnInit, ViewChild, signal } from '@angular/core'
import { trigger, transition, animate, style } from '@angular/animations'
import { fromEvent, Subscription, throttleTime } from 'rxjs'
import { HotkeyInfoComponent } from '../../../components/ui-help/hotkey-info/hotkey-info.component'
import { NgIf, NgStyle, NgClass, NgFor } from '@angular/common'
import type { SafeHtml } from '@angular/platform-browser'

@Component({
  selector: 'aw-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  animations: [
    trigger('appear', [
      transition(':leave', [style({ opacity: 1 }), animate('.2s ease-in', style({ opacity: 0 }))]),
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-10%)' }),
        animate('.4s ease-out', style({ opacity: 1, transform: 'translateX(0%)' }))
      ])
    ]),
    trigger('appearTop', [
      transition(':leave', [style({ opacity: 1 }), animate('.2s ease-in', style({ opacity: 0 }))]),
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-10%)' }),
        animate('.3s ease-out', style({ opacity: 1, transform: 'translateY(0%)' }))
      ])
    ])
  ],
  standalone: true,
  imports: [NgIf, NgStyle, NgClass, NgFor, HotkeyInfoComponent]
})
export class TooltipComponent implements OnInit, OnDestroy {
  @ViewChild('actionTip') actionTip: ElementRef
  content = signal<string | SafeHtml>('')
  action: boolean
  top = '-9999px'
  left = '-9999px'
  callOutX = '0px'
  fixed = false
  fromTop = false
  icon: string
  iconColor: string
  firstKey: string | number
  secondKey: string | number
  thirdKey: string | number
  followMouse: boolean
  width: number

  private mouseSubscription: Subscription

  constructor(public element: ElementRef) {}

  ngOnInit() {
    if (this.followMouse) {
      this.mouseSubscription = fromEvent<MouseEvent>(window.document, 'mousemove')
        .pipe(throttleTime(16))
        .subscribe(mouseEvent => {
          // Make tooltip follow mouse
          this.top = mouseEvent.clientY - 50 + 'px'
          this.left = mouseEvent.clientX - 60 + 'px'
        })
    }
  }

  ngOnDestroy() {
    if (this.mouseSubscription && !this.mouseSubscription.closed) {
      this.mouseSubscription.unsubscribe()
    }
  }
}
