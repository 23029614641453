import type { Notification } from '@awork/framework/models/notification.model'
import type { Project } from '@awork/features/project/models/project.model'

export type ValidEntityTypes = 'projects' | 'tasks' | 'timetrackings'
export type GroupingType = 'event-entity-user' | 'event-entity' | 'event-user' | 'event-reminder' | 'no-group'
export type NotificationType = 'event' | 'reminder'
export type NotificationGroupType = 'conversations' | 'eventNotifications'
export type ConversationType = 'unseen' | 'seen'
export type ProjectGroupType = 'projectUpdates'
export type TaskGroupType = 'newTasks' | 'unassignedTasks' | 'taskPredecessorDone' | 'privateTaskUpdates'
export type EventNotificationType = 'newProjects' | 'deadlines' | 'birthdays' | 'reminders' | 'default' | TaskGroupType

export interface SnoozeNotification {
  notification: Notification
  offset: RemindMeOffsets
}

export interface ProjectConversations {
  projectName?: string
  project: Project
  notifications: Notification[]
  seen?: boolean
}

export interface ProjectUpdateGroup {
  project: Project
  updatedStatus?: Notification[]
  seen?: boolean
}

export interface NotificationsGroup {
  groupType: EventNotificationType | ConversationType
  groups: Notification[]
}

export interface ProjectNotificationsGroup {
  groupType: ProjectGroupType
  groups: ProjectUpdateGroup[]
}

export interface TaskNotificationsGroup {
  groupType: TaskGroupType
  groups: Notification[]
}

export type NotificationGroup = NotificationsGroup | ProjectNotificationsGroup | TaskNotificationsGroup
export type NotificationGroups = (NotificationsGroup | ProjectNotificationsGroup | TaskNotificationsGroup)[]

export interface GroupedNotifications {
  conversations: NotificationGroup[]
  eventNotifications: NotificationGroup[]
}

export enum NotificationEvents {
  TaskStatusChanged = 'task_status_changed',
  TaskAssigned = 'task_assigned',
  TaskUnassigned = 'task_unassigned',
  TaskCommented = 'task_commented',
  TaskCommentReacted = 'task_comment_reacted',
  TaskDue = 'task_due',
  ProjectStatusChanged = 'project_status_changed',
  ProjectAssigned = 'project_assigned',
  ProjectCommented = 'project_commented',
  ProjectCommentReacted = 'project_comment_reacted',
  ProjectDue = 'project_due',
  ProjectDueWeekend = 'project_due_weekend',
  WeeklyTimeEntriesReport = 'report_time_entries_week',
  MonthlyTimeEntriesReport = 'report_time_entries_month',
  Birthday = 'birthday',
  ExportAvailable = 'export_available',
  TaskScheduleSyncCompleted = 'task_schedule_sync_completed',
  WeeklyDueTasksSummary = 'weekly_due_tasks_summary',
  TimeTrackingReminder = 'timetracking_reminder',
  ProjectMentioned = 'project_comment_mentioned',
  TaskMentioned = 'task_comment_mentioned',
  CommentMentioned = 'comment_mentioned',
  CommentReacted = 'comment_reacted',
  TaskPredecessorFinished = 'task_predecessor_finished',
  TasksImportedFinished = 'import_tasks_finished'
}

export enum RemindMeOffsets {
  OneHour = '1H',
  ThreeHours = '3H',
  Tomorrow = 'TOMORROW',
  NextWeek = 'NEXT_WEEK'
}

export const CONVERSATION_TYPES = [
  NotificationEvents.TaskCommented,
  NotificationEvents.TaskMentioned,
  NotificationEvents.ProjectCommented,
  NotificationEvents.ProjectMentioned,
  NotificationEvents.CommentReacted,
  NotificationEvents.TaskCommentReacted,
  NotificationEvents.ProjectCommentReacted,
  NotificationEvents.CommentMentioned
]
export const EVENT_NOTIFICATION_TYPES = [
  NotificationEvents.ProjectAssigned,
  NotificationEvents.TaskAssigned,
  NotificationEvents.TaskStatusChanged,
  NotificationEvents.TaskUnassigned,
  NotificationEvents.TaskPredecessorFinished,
  NotificationEvents.TaskDue,
  NotificationEvents.ProjectDue,
  NotificationEvents.ProjectDueWeekend,
  NotificationEvents.Birthday,
  NotificationEvents.ExportAvailable,
  NotificationEvents.TaskScheduleSyncCompleted,
  NotificationEvents.TasksImportedFinished,
  NotificationEvents.TaskStatusChanged,
  NotificationEvents.ProjectStatusChanged
]
