/*
  Model for file upload response from API
 */

import { apiEndpoint, isLiveMobile } from '@awork/environments/environment'
import { AccountQuery } from '@awork/_shared/state/account.query'
import { Task } from '@awork/features/task/models/task.model'
import { ExternalProviders } from '@awork/_shared/models/file-external.model'
import { assetsUrl } from '@awork/environments/environment'
import { MiddleEllipsisPipe } from '@awork/_shared/pipes/middle-ellipsis-pipe/middle-ellipsis-pipe'
import { User } from '@awork/features/user/models/user.model'
import { SelectOption } from '@awork/_shared/models/select-option.model'
import { Color } from '@awork/_shared/types/color'
import { generateGUID } from '@awork/_shared/functions/guid-generator'
import { ValidFileEntities, ValidFileEntityTypes } from '@awork/_shared/services/file-service/types'

export interface FileExtInfo {
  color: Color
  category: FileCategory
}

export enum FileOptionsValues {
  local = 'local-file',
  google = 'google-drive-file',
  oneDrive = 'one-drive-file'
}

export enum FileUploadStatus {
  Uploading,
  Complete,
  Canceled,
  Error
}

export type FileCategory =
  | 'document'
  | 'image'
  | 'presentation'
  | 'pdf'
  | 'spreadsheet'
  | 'video'
  | 'audio'
  | 'other'
  | 'package'
  | 'adobe'
export type FileTypes = 'entity' | 'global' | 'temp'

export interface FileVersion {
  id: string
  fileInfoId: string
  version: number
  size: number
  width: number
  height: number
  createdOn: Date
  createdBy: string
  updatedOn: Date
  updatedBy: string
}

interface IFileUpload {
  id: string
  trackId?: string
  filename: string
  entityId?: string
  entityType?: ValidFileEntityTypes
  entity?: ValidFileEntities
  task?: Task
  mimeType: string
  createdOn?: Date
  createdBy?: string
  username?: string
  updatedOn?: Date
  updatedBy?: string
  fileVersions?: FileVersion[]
  name: string
  description?: string
  file?: File
  progress?: number
  status?: FileUploadStatus
  needsLink?: boolean
  type?: FileTypes
  content?: [{ data: []; table: string }]
  externalProvider?: ExternalProviders
  externalFileId?: string
  externalFileUrl?: string
  isHiddenForConnectUsers?: boolean
}

export class FileUpload implements IFileUpload {
  id: string
  trackId?: string
  filename: string
  entityId?: string
  entityType?: ValidFileEntityTypes
  entity?: ValidFileEntities
  task?: Task
  mimeType: string
  createdOn?: Date
  createdBy?: string
  createdByUser?: User
  username?: string
  updatedOn?: Date
  updatedBy?: string
  fileVersions?: FileVersion[]
  name: string
  description?: string
  file?: File
  progress?: number
  status?: FileUploadStatus
  needsLink?: boolean
  type?: FileTypes
  content?: [{ data: []; table: string }]
  externalProvider?: ExternalProviders
  externalFileId?: string
  externalFileUrl?: string
  isHiddenForConnectUsers?: boolean

  constructor(fileUpload: Partial<IFileUpload>) {
    Object.assign(this, fileUpload)

    this.trackId = fileUpload.trackId || generateGUID()
    this.id = fileUpload.id || generateGUID()

    if (fileUpload.task) {
      this.task = new Task(fileUpload.task)
    }
  }

  /**
   * Gets the download url of the attachment
   * @return {string}
   */
  get downloadUrl(): string {
    let queryOptions = ''
    // To fetch the images also locally
    if (isLiveMobile && AccountQuery.instance) {
      queryOptions = '?'
      const accountState = AccountQuery.instance.getAccount()
      if (accountState) {
        const accessToken = accountState.accessToken as string
        queryOptions += new URLSearchParams({ jwt: accessToken, 'aw-mobile': 'true' } as any).toString()
      }
    }

    if (this.type !== 'temp') {
      return `${apiEndpoint}/files/${this.id}/download${queryOptions}`
    } else {
      return `${apiEndpoint}/temporaryfiles/${this.id}/download${queryOptions}`
    }
  }

  /**
   * Returns the file's extension
   * @returns {string}
   */
  static getFileExtension(fileName: string): string {
    const regExp = /(?:\.([^.]+))?$/
    return regExp.exec(fileName)?.[1]
  }

  /**
   * Returns true if the mimeType is an image supported by browser
   * @param mimeType
   */
  static isImageMimeType(mimeType: string): boolean {
    if (!mimeType) {
      return false
    }

    const excludedMimeTypes = ['image/tiff', 'image/svg+xml', 'image/vnd.adobe.photoshop']

    return mimeType?.includes('image') && !excludedMimeTypes.includes(mimeType)
  }

  /**
   * Checks if file type is an image
   */
  isImage(): boolean {
    return FileUpload.isImageMimeType(this.mimeType)
  }

  /**
   * Determines if the image can be previewed.
   * Note: If there is an issue processing the image in the File service, it does not have width or height.
   * @returns {boolean}
   */
  isPreviewableImage(): boolean {
    return this.isImage() && !!this.fileVersions?.[0]?.width && !!this.fileVersions?.[0]?.height
  }

  isVideo(): boolean {
    const extension = FileUpload.getFileExtension(this.name)

    return ['mov', 'mp4', 'ogg', 'webm'].includes(extension)
  }

  isPDFPreviewSupported(): boolean {
    const officeMimeTypes = [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      'application/vnd.ms-word.document.macroEnabled.12',
      'application/vnd.ms-word.template.macroEnabled.12',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    ]

    return [...officeMimeTypes, 'application/pdf'].includes(this.mimeType)
  }

  /**
   * Determines if the file can be previewed.
   * The file needs to be an image, a video, a pdf, or an office document.
   * Also, if it is an image the file needs to be less than 5MB (Performance issue).
   * @returns {boolean}
   */
  isPreviewSupported(): boolean {
    if (this.externalFileUrl) {
      return false
    }

    return this.isPreviewableImage() || this.isVideo() || this.isPDFPreviewSupported()
  }

  /**
   * Gets the file size of the attachment
   * @return {number}
   */
  get size(): number {
    if (this.fileVersions && this.fileVersions.length > 0) {
      return this.fileVersions[0].size
    }
    return 0
  }

  /**
   * Get Files Previews
   * @return {string}
   */

  get filePreview(): string {
    let queryOptions = ''

    // To fetch the images also locally
    if (isLiveMobile) {
      queryOptions = '?'
      const accountState = AccountQuery?.instance?.getAccount()
      if (accountState) {
        const accessToken = accountState.accessToken as string
        queryOptions += new URLSearchParams({ jwt: accessToken, 'aw-mobile': 'true' } as any).toString()
      }
    }
    if (this.entityId) {
      return `${apiEndpoint}/${this.entityType}/${this.entityId}/files/${this.id}/preview${queryOptions}`
    } else {
      return `${apiEndpoint}/temporaryfiles/${this.id}/preview${queryOptions}`
    }
  }

  /**
   * Returns the name of the file's category
   * https://www.computerhope.com/issues/ch001789.htm
   * @returns {FileCategory}
   */
  get fileCategory(): FileCategory {
    const extension = FileUpload.getFileExtension(this.name)
    if (!extension) {
      return null
    }

    const fileMap = {
      document: ['doc', 'docx', 'odt', 'rtf', 'tex', 'txt', 'wpd', 'pdf', 'epub', 'odp', 'document'],
      image: [
        'bmp',
        'gif',
        'ico',
        'jpeg',
        'jpg',
        'png',
        'svg',
        'tif',
        'tiff',
        'heic',
        'eps',
        'raw',
        'sketch',
        'icn',
        'webp',
        'ico'
      ],
      presentation: ['key', 'odp', 'pps', 'ppt', 'pptx', 'presentation'],
      audio: ['aif', 'cda', 'mid', 'midi', 'mp3', 'mpa', 'ogg', 'wav', 'wma', 'wp'],
      spreadsheet: ['csv', 'ods', 'xls', 'xlsx', 'lsm', 'lsx', 'spreadsheet'],
      video: [
        '3g2',
        '3gp',
        'avi',
        'flv',
        'h264',
        'm4v',
        'mkv',
        'mov',
        'mp4',
        'mpg',
        'mpeg',
        'rm',
        'swf',
        'vob',
        'wmv',
        'webm'
      ],
      code: ['htm', 'html', 'js', 'css', 'scss', 'csharp', 'c', 'vb', 'ts', 'json', 'xhtml', 'xml', 'manifest', 'git'],
      apps: ['exe', 'dmg', 'bat', 'jar', 'msi'],
      package: ['zip', 'rar', '7z'],
      font: ['eot', 'woff', 'woff2', 'ttf'],
      graphics: ['stl', 'obj', '3ds', 'iges', 'step', 'fbx', 'x3d'],
      adobe: ['ai', 'indd', 'aep', 'prproj', 'psd', 'ps', 'xd'],
      email: ['msg'],
      appointment: ['ics']
    }
    const categories = Object.keys(fileMap) as FileCategory[]

    return categories.find(category => fileMap[category].includes(extension))
  }

  /**
   * Returns information about a given file extention
   * @param {string} text
   * @returns {FileExtInfo}
   */
  static getExtInfo(ext: string): FileExtInfo {
    const blueDocument = { color: Color.Blue, category: 'document' }
    const redDocument = { color: Color.Red, category: 'document' }
    const royalBlueImage = {
      color: Color.RoyalBlue,
      category: 'image'
    }
    const yellowImage = { color: Color.Yellow, category: 'image' }
    const orangePresentation = { color: Color.Orange, category: 'presentation' }
    const lightGreenAudio = { color: Color.LightGreen, category: 'audio' }
    const greenSpreadsheet = { color: Color.Green, category: 'spreadsheet' }
    const pinkVideo = { color: Color.Pink, category: 'video' }
    const deepYellowPackage = { color: Color.DeepYellow, category: 'package' }
    const purplePackage = { color: Color.Purple, category: 'package' }
    const deepOrangeAdobe = { color: Color.DeepOrange, category: 'adobe' }
    const deepPinkAdobe = { color: Color.DeepPink, category: 'adobe' }
    const deepBlueAdobe = { color: Color.DeepBlue, category: 'adobe' }
    const lightBlueAdobe = { color: Color.LightBlue, category: 'adobe' }
    const lightPinkAdobe = { color: Color.LightPink, category: 'adobe' }
    const defaultInfo = { color: Color.Blue, category: null }

    const extInfoMap = {
      doc: blueDocument,
      docx: blueDocument,
      document: blueDocument,
      pdf: redDocument,
      bmp: royalBlueImage,
      gif: royalBlueImage,
      ico: royalBlueImage,
      jpeg: royalBlueImage,
      png: royalBlueImage,
      svg: royalBlueImage,
      tif: royalBlueImage,
      tiff: royalBlueImage,
      heic: royalBlueImage,
      eps: royalBlueImage,
      raw: royalBlueImage,
      webp: royalBlueImage,
      sketch: yellowImage,
      pps: orangePresentation,
      ppt: orangePresentation,
      pptx: orangePresentation,
      presentation: orangePresentation,
      aif: lightGreenAudio,
      cda: lightGreenAudio,
      mid: lightGreenAudio,
      midi: lightGreenAudio,
      mp3: lightGreenAudio,
      mpa: lightGreenAudio,
      ogg: lightGreenAudio,
      wav: lightGreenAudio,
      wma: lightGreenAudio,
      wp: lightGreenAudio,
      xls: greenSpreadsheet,
      xlsx: greenSpreadsheet,
      csv: greenSpreadsheet,
      spreadsheet: greenSpreadsheet,
      avi: pinkVideo,
      flv: pinkVideo,
      h264: pinkVideo,
      m4v: pinkVideo,
      mkv: pinkVideo,
      mov: pinkVideo,
      mp4: pinkVideo,
      mpg: pinkVideo,
      mpeg: pinkVideo,
      rm: pinkVideo,
      swf: pinkVideo,
      vob: pinkVideo,
      wmv: pinkVideo,
      webm: pinkVideo,
      zip: deepYellowPackage,
      rar: purplePackage,
      ai: deepOrangeAdobe,
      indd: deepPinkAdobe,
      aep: deepBlueAdobe,
      prproj: deepBlueAdobe,
      psd: lightBlueAdobe,
      ps: lightBlueAdobe,
      xd: lightPinkAdobe
    }

    return extInfoMap[ext] || defaultInfo
  }

  /**
   * Get a size reduced image
   * @return {string}
   */

  get reducedImage(): string {
    let queryOptions: {} = {
      crop: false,
      width: 1024,
      height: 1024,
      enlarge: false,
      v: new Date(this.updatedOn).getTime()
    }

    if (isLiveMobile) {
      const accountState = AccountQuery.instance.getAccount()
      if (accountState) {
        const accessToken = accountState.accessToken as string
        queryOptions = {
          ...queryOptions,
          jwt: accessToken,
          'aw-mobile': 'true'
        }
      }
    }

    if (this.type !== 'temp') {
      return `${apiEndpoint}/files/${this.id}/download?${new URLSearchParams(queryOptions).toString()}`
    } else {
      return `${apiEndpoint}/temporaryfiles/${this.id}/download?${new URLSearchParams(queryOptions).toString()}`
    }
  }

  /**
   * Gets external provider icon for external files
   * @returns {string}
   */
  get externalIcon(): string {
    return FileUpload.GetIconByProvider(this.externalProvider)
  }

  /**
   * Returns external provider icon for external files
   * @param {ExternalProviders} externalProvider
   * @returns {string}
   */
  static GetIconByProvider(externalProvider: ExternalProviders): string {
    switch (externalProvider) {
      case ExternalProviders.google:
        return assetsUrl + 'images/integrations/google_drive_icon.svg'
      case ExternalProviders.oneDrive:
        return assetsUrl + 'images/integrations/onedrive_icon.svg'
      default:
        return null
    }
  }

  /**
   * Gets the file context menu options
   * @param {boolean} isMSteams
   * @returns {ContextMenuOption[]}
   */
  static getFileContextMenuOption(isMSteams: boolean = false, translations): SelectOption[] {
    return [
      {
        icon: 'attach_file',
        label: translations.ProjectDocsComponent.local,
        value: FileOptionsValues.local
      },
      {
        image: q.assetsUrl + 'images/integrations/google_drive_icon.svg',
        label: translations.ProjectDocsComponent.google,
        value: FileOptionsValues.google
      },
      {
        image: q.assetsUrl + 'images/integrations/onedrive_icon.svg',
        label: translations.ProjectDocsComponent.oneDrive,
        value: FileOptionsValues.oneDrive
      }
    ].filter(option => !isMSteams || option.value === FileOptionsValues.local)
  }

  /**
   * Gets the file extension from the files name
   * @returns {string}
   */
  get fileExtension(): string {
    return FileUpload.getFileExtension(this.name)
  }

  /**
   * Gets file trimmed name
   * @returns {string}
   */
  get fileTrimmedName(): string {
    const stringMiddleTrimPipe = new MiddleEllipsisPipe()

    return stringMiddleTrimPipe.transform(this.name, 25, '...')
  }
}
