import { dateToNumeric } from '@awork/_shared/functions/date-operations'

export enum ProjectMilestoneColor {
  Blue = 'blue',
  Purple = 'purple',
  Red = 'red',
  Yellow = 'yellow',
  Green = 'green',
  Asphalt = 'asphalt'
}

interface IProjectMilestone {
  id: string
  projectId: string
  name: string
  color: ProjectMilestoneColor
  dueDate: string | Date
}

export class ProjectMilestone {
  id: string
  name: string
  projectId: string
  color: ProjectMilestoneColor
  dueDate: Date | string

  constructor(milestone: IProjectMilestone) {
    Object.assign(this, milestone)

    // Delete unnecessary properties that come from the API
    delete this['project']

    this.dueDate = new Date(this.dueDate)
  }

  static createEmpty(): ProjectMilestone {
    return new ProjectMilestone({
      id: null,
      projectId: null,
      name: null,
      dueDate: null,
      color: null
    })
  }

  static getColors(): ProjectMilestoneColor[] {
    return Object.values(ProjectMilestoneColor)
  }

  static getDateIndex(date: Date): number {
    return dateToNumeric(date)
  }

  /**
   * Gets the index used for mapping
   */
  get index(): number {
    return ProjectMilestone.getDateIndex(this.dueDate as Date)
  }

  get startOn(): Date {
    return this.dueDate as Date
  }

  get endOn(): Date {
    return this.dueDate as Date
  }
}
