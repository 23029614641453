import { apiEndpoint, isLiveMobile } from '@awork/environments/environment'
import { Contact } from '@awork/_shared/models/contact.model'
import { ProjectMember } from '@awork/features/project/models/project-member.model'
import { getPixelRatio } from '@awork/_shared/functions/browser-infos'
import { getAssetImage } from '@awork/_shared/functions/get-asset-image'
import { AccountQuery } from '@awork/_shared/state/account.query'
import { Tag } from '@awork/_shared/models/tag.model'
import { Team } from '@awork/features/team/models/team.model'
import { adjustDateToLocalDateStart } from '@awork/_shared/functions/date-operations'
import { IUserBase, UserBase } from '@awork/features/user/models/user.model.base'
import { SECONDS_IN_HOUR } from '@awork/_shared/functions/time-operations'
import {
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isSaturday,
  isSunday,
  addDays
} from '@awork/_shared/functions/date-fns-wrappers'
import { Absence } from '@awork/features/user/models/absence.model'
import { HolidayRegion } from './holiday-region.model'
import { WorkspaceAbsence } from './workspace-absence.model'

export const aworkGenericId = '01010001-0000-0000-0000-111111111111'
export type UserGender = 'female' | 'male' | 'other'

export interface UserStatus {
  isActivated: boolean
  invitationAccepted: boolean
}

export interface CapacityPerWeek {
  mon: number
  tue: number
  wed: number
  thu: number
  fri: number
  sat: number
  sun: number
}

export interface UserPlanningSettings {
  userId: string
  capacityPerWeek: number // Deprecated: will be dropped once the api removes it
  weeklyCapacity: CapacityPerWeek
}

export interface UserProjectLastActivity {
  id: string
  eventDate: string
  projectId: string
  userId: string
}

export interface IUser {
  position?: string
  gender?: string
  title?: string
  birthDate?: string | Date
  accountId?: string
  isDeactivated?: boolean
  status?: UserStatus
  role?: string
  tags?: Tag[]
  teams?: Team[]
  userContactInfos?: Contact[]
  resourceVersion?: number // the version from the API MV
  capacityPerWeek?: CapacityPerWeek
  publicImageToken?: string
  isExternal?: boolean
  shouldMigrateToConnect?: boolean
  holidayRegion?: HolidayRegion
  workspaceAbsence?: WorkspaceAbsence
}

export class User extends UserBase implements IUser {
  position?: string
  gender?: string
  title?: string
  birthDate?: string | Date
  accountId?: string
  isDeactivated?: boolean
  status?: UserStatus
  role?: string
  tags?: Tag[] = []
  teams?: Team[]
  userContactInfos?: Contact[] = []
  resourceVersion: number
  capacityPerWeek?: CapacityPerWeek
  typeName = 'User'
  publicImageToken?: string
  isExternal?: boolean
  shouldMigrateToConnect?: boolean
  holidayRegion?: HolidayRegion
  workspaceAbsence?: WorkspaceAbsence

  constructor(user: IUser & IUserBase, caller: string) {
    super(user)

    if (user) {
      if (user['initials']) {
        // HACK: Prevent TypeError "Cannot set property initials of object
        // which has only a getter" instead of fixing the underlying issue
        delete user['initials']
      }

      if (user['userId']) {
        // HACK: Map project members here instead of fixing the underlying
        // issue
        this.id = user['userId']
      }

      if (this.createdOn) {
        this.createdOn = new Date(this.createdOn)
      }

      if (this.updatedOn) {
        this.updatedOn = new Date(this.updatedOn)
      }

      if (user.userContactInfos) {
        this.userContactInfos = user.userContactInfos.map(contact => new Contact(contact))
      }

      if (this.teams) {
        this.teams = this.teams.map(team => new Team(team))
      }

      if (user.tags) {
        this.tags = user.tags
      }

      if (typeof user.capacityPerWeek === 'number') {
        const capacity = user.capacityPerWeek / 5

        this.capacityPerWeek = {
          mon: capacity,
          tue: capacity,
          wed: capacity,
          thu: capacity,
          fri: capacity,
          sat: 0,
          sun: 0
        }
      }
    }
  }

  static isUser(user: any): user is User {
    return (user as User)?.typeName === 'User'
  }

  /**
   * returns generic awork user
   * @param {string} caller
   * @returns {User}
   */
  static getAworkUser(caller: string): User {
    return new User({ id: aworkGenericId, firstName: 'awork', hasImage: true, updatedOn: new Date() }, caller)
  }

  static isAworkGenericUser(id: string): boolean {
    return id === aworkGenericId
  }

  static getAworkGenericId(): string {
    return aworkGenericId
  }

  /**
   * Creates the default capacity of 40h per week: 8h (mon-fri) in seconds
   * @returns {CapacityPerWeek}
   */
  static getDefaultCapacityPerWeek(): CapacityPerWeek {
    return {
      mon: 28800,
      tue: 28800,
      wed: 28800,
      thu: 28800,
      fri: 28800,
      sat: 0,
      sun: 0
    }
  }

  get fullName(): string {
    const firstName = this.firstName || ''
    const lastName = this.lastName || ''

    const fullName = this.trimMultipleSpaces(`${firstName} ${lastName}`)
    return fullName ? fullName : this.email ? this.email.split('@')[0] : '(No name)'
  }

  set fullName(name: string) {
    const [firstName, ...restName] = this.trimMultipleSpaces(name).split(' ')
    this.firstName = this.undefinedEmptyString(firstName)
    this.lastName = this.undefinedEmptyString(restName.join(' '))
  }

  get shortName(): string {
    return this.firstName || this.lastName
  }

  get name(): string {
    return this.shortName
  }

  set name(_: string) {}

  get initials(): string {
    const specialCharRegex: RegExp = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g
    // Removing the special character in the first name and taking the first letter
    const firstNameInitial = this.firstName ? this.firstName.replace(specialCharRegex, '').charAt(0) : ''
    // Checking if the first letter in the last name is a special character. If not, takes the first letter
    const lastNameInitial =
      this.lastName && !specialCharRegex.test(this.lastName.charAt(0)) ? this.lastName.charAt(0) : ''

    return `${firstNameInitial}${lastNameInitial}`
  }

  get urlName(): string {
    return decodeURIComponent(this.fullName.replace(/ /g, '-'))
  }

  get birthDateLocal(): Date {
    if (this.birthDate) {
      return adjustDateToLocalDateStart(this.birthDate)
    }
    return null
  }

  get birthDayCurrentYear(): Date {
    if (this.birthDateLocal) {
      const bDate = this.birthDateLocal
      return new Date(new Date().getFullYear(), bDate.getMonth(), bDate.getDate())
    }
    return null
  }

  get image(): string {
    return this.profileImage()
  }

  static defaultProfileImage(): string {
    return getAssetImage('visuals/dummy-profile-image.svg')
  }

  static isProjectMember(user: User | ProjectMember): user is ProjectMember {
    return (<ProjectMember>user).userId !== undefined
  }

  static mapMembersToUsers(projectMembers: ProjectMember[]): User[] {
    if (projectMembers?.length) {
      return projectMembers.filter(member => !!member?.user?.fullName).map(member => member.user)
    } else {
      return []
    }
  }

  /**
   * Gets Q itself
   * @return {User}
   */
  static getQ(): User {
    return new User({ id: '0', firstName: 'Q', hasImage: false }, 'User#getQ')
  }

  /**
   * Returns the workspace image of the user
   */
  getWorkspaceImage(): string {
    return `${apiEndpoint}/files/images/users/${this.id}/workspace`
  }

  profileImage(width = 500, height = 500): string {
    if (this.publicImageToken) {
      return `${apiEndpoint}/files/images/users?${new URLSearchParams({ token: this.publicImageToken }).toString()}`
    }

    if (this.id === aworkGenericId) {
      return getAssetImage('visuals/logo-white-on-blue.svg')
    }

    const userId = User.isProjectMember(this) ? (<ProjectMember>this).userId : this.id
    const pixelRatio = getPixelRatio()
    let queryOptions: any = {
      crop: false,
      width: Math.floor(width * pixelRatio),
      height: Math.floor(height * pixelRatio),
      v: this.updatedOn ? new Date(this.updatedOn).getTime() : null
    }

    // To fetch the images also locally
    if (isLiveMobile && AccountQuery.instance) {
      const accountState = AccountQuery.instance.getAccount()
      if (accountState) {
        const accessToken = accountState.accessToken as string
        queryOptions = {
          ...queryOptions,
          jwt: accessToken,
          'aw-mobile': 'true'
        }
      }
    }

    // Set the url
    const url = `${apiEndpoint}/files/images/users/${userId}?${new URLSearchParams(queryOptions).toString()}`

    // Fallback to default if there is no image, or there is no access
    // token - otherwise the image requests would be unauthorized
    const shouldUseDefault = isLiveMobile ? !this.hasImage || queryOptions.jwt === null : !this.hasImage

    return shouldUseDefault ? User.defaultProfileImage() : url
  }

  /**
   * Returns the default email address of a user
   */
  get email(): string {
    if (this.userContactInfos) {
      const emailItem = this.userContactInfos.find(x => x.type === 'email' && x.value !== '')
      if (emailItem) {
        return emailItem.value
      }
    }
    return ''
  }

  private trimMultipleSpaces(s: string): string {
    return s.replace(/\s\s+/g, ' ').trim()
  }

  private undefinedEmptyString(s: string): string | undefined {
    return s.trim() === '' ? undefined : s
  }

  /**
   * Returns the total capacity per week in seconds
   * @returns {number}
   */
  get capacityPerWeekTotal(): number {
    let capacityPerWeek = this.capacityPerWeek

    if (!capacityPerWeek) {
      capacityPerWeek = User.getDefaultCapacityPerWeek()
    }

    return Object.values(capacityPerWeek || {}).reduce((total: number, current: number) => total + current, 0)
  }

  /**
   * @deprecated
   * The capacity per week is now set by week day, so this calculation is not
   * correct and should not be used further.
   * Use #capacityPerDay and divide by SECONDS_IN_HOUR instead
   * @returns {number}
   */
  get hoursPerDay(): number {
    return this.capacityPerWeekTotal / SECONDS_IN_HOUR / 5
  }

  /**
   * Returns the capacity per day.
   * @param {Date | string} date
   * @returns {number}
   */
  capacityPerDay(date: Date | string): number {
    let capacityPerWeek = this.capacityPerWeek

    if (!capacityPerWeek) {
      capacityPerWeek = User.getDefaultCapacityPerWeek()
    }

    if (isMonday(date)) {
      return capacityPerWeek.mon
    } else if (isTuesday(date)) {
      return capacityPerWeek.tue
    } else if (isWednesday(date)) {
      return capacityPerWeek.wed
    } else if (isThursday(date)) {
      return capacityPerWeek.thu
    } else if (isFriday(date)) {
      return capacityPerWeek.fri
    } else if (isSaturday(date)) {
      return capacityPerWeek.sat
    } else if (isSunday(date)) {
      return capacityPerWeek.sun
    }

    return 0
  }

  /**
   * Returns the total capacity
   * @param {Date} startDate
   * @param {Date} endDate
   * @returns {number}
   */
  capacityPerDateRange(startDate: Date, endDate: Date): number {
    let totalDuration = 0
    let currentDate = startDate

    if (startDate > endDate) {
      return 0
    }

    while (currentDate <= endDate) {
      totalDuration += this.capacityPerDay(currentDate)
      currentDate = addDays(currentDate, 1)
    }

    return totalDuration
  }

  /**
   * Returns the total weekly capacity in hours
   * @returns {number}
   */
  get hoursPerWeek(): number {
    return this.capacityPerWeekTotal / SECONDS_IN_HOUR
  }

  set hoursPerWeek(hours: number) {
    // TODO: Refactor with correct setter logic
    const hourPerWeekDay = (hours / 5) * SECONDS_IN_HOUR

    this.capacityPerWeek = {
      mon: hourPerWeekDay,
      tue: hourPerWeekDay,
      wed: hourPerWeekDay,
      thu: hourPerWeekDay,
      fri: hourPerWeekDay,
      sat: 0,
      sun: 0
    }
  }

  getPrimaryEmailByDomain(domain: string): string {
    const primaryItem = this.userContactInfos?.find(info => info.type === 'email' && info.value.endsWith(domain))
    return primaryItem?.value || ''
  }

  /**
   * Returns the earliest absence of the user
   */
  findEarliestAbsence(): Absence {
    return this.absences?.sort((absenceA, absenceB) => absenceA.startOn.getTime() - absenceB.startOn.getTime())[0]
  }
}
