import striptags from 'striptags'
import { textUrlRegexExtended } from '@awork/_shared/static-data/regular-expressions'
import { unescape } from '@awork/_shared/functions/lodash'

/**
 * Strips the html and replaces line breaks with default (\n) line breaks
 * @param str
 */

export function stripHTML(str: string): string {
  return striptags(str)
}

/**
 * Replaces normal text linebreaks (/n) with HTML linebreaks (<br/>), if the string doesn't contain any HTML
 * @param str
 */
export function addHtmlLinebreaks(str: string): string {
  const matchResults = str.match(/<(?:.|\n)*?>/gm)
  if (!matchResults || matchResults.length === 0) {
    str = str.replace('\n', '<br/>')
  }
  return str
}

/**
 * Trims and removes empty lines at start and end of the rich text string
 * @param str
 */
export function trimRichText(str: string): string {
  if (str) {
    const emptyLineRegex = /^(<p><br><\/p>)+|(<p><br><\/p>)+$/g

    str = str.trim()
    str = str.replace(emptyLineRegex, '')
  }

  return str
}

/**
 * Removes plain and HTML whitespaces, including empty HTML tags (or containing
 * only <br> or &nbsp;)
 * @param {string} str
 * @returns {string}
 */
export function trimWhitespaces(str: string): string {
  return str?.replace(/(<(?!br)\w+>)+(<br>|&nbsp;)*(<\/\w+>)/g, '').trim() // Remove normal whitespaces
}

/**
 * Unescapes any encoded html at the end of urls
 * @param {string} str
 * @returns {string}
 */
export function unescapeUrls(str: string): string {
  const urls = str.match(textUrlRegexExtended)
  urls?.forEach(url => {
    str = str.replace(url, unescape(url))
  })

  return str
}

/**
 * Unescapes HTML entities, converting characters such as '&amp;' to '&'.
 * @param {string} str
 * @returns {string}
 */
export function unescapeHtml(str: string): string {
  const parser = new DOMParser()
  const doc = parser.parseFromString(str, 'text/html')

  return doc.documentElement.textContent
}

/**
 * Escapes regex special characters to use with a RegExp constructor
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
 * @param {string} str
 * @returns {string}
 */
export function escapeRegExp(str): string {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

/**
 * Get string size in bytes that can be used in validations.
 * @param string
 */
export function getStringByteSize(string): number {
  if (!string) {
    return 0
  }
  return new Blob([string]).size
}

/**
 * Rotates a string using the ROT47 approach
 * (by 47 characters in the visible part of the ASCII table).
 * @param {string} input
 * @returns {string}
 */
export function rotateCharacters(input: string): string {
  const rot47Distance = 47
  const asciiVisibleStart = 33
  const asciiVisibleEnd = 126

  let result = ''
  for (let i = 0; i < input.length; i++) {
    const charCode = input.charCodeAt(i)
    if (charCode >= asciiVisibleStart && charCode <= asciiVisibleEnd) {
      const rotatedCharCode =
        ((charCode - asciiVisibleStart + rot47Distance) % (asciiVisibleEnd - asciiVisibleStart + 1)) + asciiVisibleStart
      result += String.fromCharCode(rotatedCharCode)
    } else {
      result += input.charAt(i)
    }
  }
  return result
}

/**
 * Gets Email domain name
 * @param {string} email
 * @returns {string}
 */
export function getEmailDomainName(email: string): string {
  return email.split('@')[1]
}
