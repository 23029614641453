import { ProjectStatus } from '@awork/features/project/models/project-status.model'
import { Tag } from '@awork/_shared/models/tag.model'
import { TaskBundle } from '@awork/features/task/models/task-bundle.model'
import { getPixelRatio } from '@awork/_shared/functions/browser-infos'
import { apiEndpoint, isLiveMobile } from '@awork/environments/environment'
import { AccountState } from '@awork/_shared/models/account.model'
import { AccountQuery } from '@awork/_shared/state/account.query'
import { DropdownOption } from '@awork/_shared/models/select-option.model'
import { CustomFieldDefinitionLink } from '@awork/features/project/models/custom-field-definition-link.model'

export enum IsBillableByDefaultOptions {
  on = 'on',
  off = 'off',
  auto = 'auto'
}

interface IProjectTemplate {
  id: string
  name: string
  description?: string
  projectStatuses?: ProjectStatus[]
  taskBundle?: TaskBundle
  tags?: Tag[]
  isBillableByDefault: IsBillableByDefaultOptions
  defaultTaskListIdForEmails?: string
  projectTypeId?: string
  createdOn?: Date
  createdBy?: string
  updatedOn?: Date
  updatedBy?: string
  isFavorite?: boolean
  customFieldDefinitionLinks?: CustomFieldDefinitionLink[]
}

/**
 * The project template in awork
 */
export class ProjectTemplate implements IProjectTemplate {
  id: string
  name: string
  description?: string
  projectStatuses?: ProjectStatus[]
  taskBundle?: TaskBundle
  tags?: Tag[]
  isBillableByDefault: IsBillableByDefaultOptions
  defaultTaskListIdForEmails?: string
  hasImage: boolean
  projectTypeId?: string
  createdOn?: Date
  createdBy?: string
  updatedOn?: Date
  updatedBy?: string
  isFavorite?: boolean
  customFieldDefinitionLinks?: CustomFieldDefinitionLink[]

  constructor(projectTemplate: Partial<IProjectTemplate>) {
    Object.assign(this, projectTemplate)

    if (this.customFieldDefinitionLinks) {
      this.customFieldDefinitionLinks = this.customFieldDefinitionLinks.map(
        customFieldDefinitionLink => new CustomFieldDefinitionLink(customFieldDefinitionLink)
      )
    }
  }

  static getIsBillableByDefaultSelectOptions(): DropdownOption<IsBillableByDefaultOptions>[] {
    return [
      {
        value: IsBillableByDefaultOptions.on,
        label: q.translations.ProjectTemplateModel.billabilityOn
      },
      {
        value: IsBillableByDefaultOptions.off,
        label: q.translations.ProjectTemplateModel.billabilityOff
      },
      {
        value: IsBillableByDefaultOptions.auto,
        label: q.translations.ProjectTemplateModel.billabilityAuto
      }
    ]
  }
  /**
   * Returns the account state
   * @returns {AccountState}
   */
  getAccountState(): AccountState {
    return AccountQuery.instance?.getAccount()
  }

  /**
   * Get the image url of the project template
   * @param width
   * @param height
   * @param crop
   */
  getImage(width = 500, height = 500, crop = true): string {
    if (!this.hasImage) {
      return null
    }

    const pixelRatio = getPixelRatio()

    let queryOptions: any = {
      width: Math.floor(width * pixelRatio),
      height: Math.floor(height * pixelRatio),
      v: this.updatedOn ? new Date(this.updatedOn).getTime() : undefined
    }

    if (crop) {
      queryOptions.crop = true
    }

    const accountState = this.getAccountState()

    if (isLiveMobile && !!accountState) {
      const accessToken = accountState.accessToken as string

      queryOptions = {
        ...queryOptions,
        jwt: accessToken,
        'aw-mobile': 'true'
      }
    }

    return `${apiEndpoint}/files/images/projecttemplates/${this.id}?${new URLSearchParams(queryOptions).toString()}`
  }

  /**
   * The initials of the projectTemplate
   * @returns {string}
   */
  get initials(): string {
    if (!this.name) {
      return null
    }

    const upperCaseInitials = this.name.match(/[A-Z]/g)
    const initials = upperCaseInitials || this.name.match(/\b[a-zA-Z]/g)

    return initials ? initials.slice(0, 2).toString().toUpperCase().replace(/,/g, '') : null
  }
}
