export enum PlanStatus {
  InTrial = 'InTrial',
  Cancelled = 'Cancelled',
  NonRenewing = 'NonRenewing',
  Active = 'Active'
}

export enum PlanTier {
  Free = 'free',
  Team = 'team',
  Business = 'business',
  Enterprise = 'enterprise',
  Connect = 'connect'
}

export enum PlanPrefix {
  Awork = 'awork',
  Q = 'q'
}

export enum PlanPeriod {
  Monthly = 1,
  Yearly = 12,
  Sixteen = 16,
  BiYearly = 24
}

export enum Plan {
  // ------------- Pricing plans: Current --------------- //
  Team = 'team-2023',
  Business = 'business-2023',
  Enterprise = 'enterprise-2023',
  Connect = 'connect-2024',
  // ------------- Pricing plans: Legacy --------------- //
  Internal = 'internalfree',
  Free = 'free',
  NewFree = 'free-new',
  LegacyStandard = 'standard',
  LegacyEnterprise = 'enterprise',
  LegacyBuhl = 'buhl'
}

export enum PlanId {
  // Current plans
  TeamMonthly = `${Plan.Team}-${PlanPeriod.Monthly}`,
  TeamYearly = `${Plan.Team}-${PlanPeriod.Yearly}`,
  TeamBiYearly = `${Plan.Team}-${PlanPeriod.BiYearly}`,
  BusinessMonthly = `${Plan.Business}-${PlanPeriod.Monthly}`,
  BusinessYearly = `${Plan.Business}-${PlanPeriod.Yearly}`,
  BusinessBiYearly = `${Plan.Business}-${PlanPeriod.BiYearly}`,
  EnterpriseYearly = `${Plan.Enterprise}-${PlanPeriod.Yearly}`,
  EnterpriseBiYearly = `${Plan.Enterprise}-${PlanPeriod.BiYearly}`,
  Connect = `${Plan.Connect}-${PlanPeriod.Monthly}`,
  // Legacy plans
  AworkStandardMonthly = `${PlanPrefix.Awork}-${Plan.LegacyStandard}-${PlanPeriod.Monthly}`,
  AworkStandardYearly = `${PlanPrefix.Awork}-${Plan.LegacyStandard}-${PlanPeriod.Yearly}`,
  AworkStandardBiYearly = `${PlanPrefix.Awork}-${Plan.LegacyStandard}-${PlanPeriod.BiYearly}`,
  AworkEnterpriseMonthly = `${PlanPrefix.Awork}-${Plan.LegacyEnterprise}-${PlanPeriod.Monthly}`,
  AworkEnterpriseYearly = `${PlanPrefix.Awork}-${Plan.LegacyEnterprise}-${PlanPeriod.Yearly}`,
  AworkEnterpriseBiYearly = `${PlanPrefix.Awork}-${Plan.LegacyEnterprise}-${PlanPeriod.BiYearly}`,
  AworkEnterpriseVolumeVDMBiYearly = `${PlanPrefix.Awork}-${Plan.LegacyEnterprise}-volume-vdm-${PlanPeriod.BiYearly}`,
  QBuhlMonthly = `${PlanPrefix.Q}-${Plan.LegacyBuhl}-${PlanPeriod.Monthly}`,
  QBuhlYearly = `${PlanPrefix.Q}-${Plan.LegacyBuhl}-${PlanPeriod.Yearly}`,
  QStandardMonthly = `${PlanPrefix.Q}-${Plan.LegacyStandard}-${PlanPeriod.Monthly}`,
  QStandardSpecialSixteen = `${PlanPrefix.Q}-${Plan.LegacyStandard}-${PlanPeriod.Monthly}-16`,
  QStandardYearly = `${PlanPrefix.Q}-${Plan.LegacyStandard}-${PlanPeriod.Yearly}`,
  QStandardBiYearly = `${PlanPrefix.Q}-${Plan.LegacyStandard}-${PlanPeriod.BiYearly}`,
  QEnterpriseMonthly = `${PlanPrefix.Q}-${Plan.LegacyEnterprise}-${PlanPeriod.Monthly}`,
  QEnterpriseYearly = `${PlanPrefix.Q}-${Plan.LegacyEnterprise}-${PlanPeriod.Yearly}`,
  QEnterpriseBiYearly = `${PlanPrefix.Q}-${Plan.LegacyEnterprise}-${PlanPeriod.BiYearly}`,
  // Free plans
  QLegacyFreeMonthly = `${PlanPrefix.Q}-${Plan.Free}-${PlanPeriod.Monthly}`,
  QFreeMonthly = `${PlanPrefix.Q}-${Plan.NewFree}-${PlanPeriod.Monthly}`,
  QInternalFreeMonthly = `${PlanPrefix.Q}-${Plan.Internal}-${PlanPeriod.Monthly}`
}

export enum PlanVersion {
  Legacy = 'legacy',
  Current = 'current'
}
