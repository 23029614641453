export enum Team {
  Architecture = 'architecture',
  Framework = 'framework',
  TimeTracking = 'time-tracking',
  WorkManagement = 'work-management',
  Growth = 'growth',
  Marketing = 'marketing',
  Design = 'design'
}

export enum TeamMember {
  Andres = 'andres',
  Annalisa = 'annalisa',
  Carolina = 'carolina',
  Davide = 'davide',
  Fabian = 'fabian',
  Ian = 'ian',
  Leandro = 'leandro',
  Leonardo = 'leonardo',
  Lucas = 'lucas',
  Marcello = 'marcello',
  Marcelo = 'marcelo',
  Marek = 'marek',
  Mika = 'mika',
  Max = 'max',
  Nils = 'nils',
  Samuel = 'samuel',
  Santiago = 'santiago',
  Vitor = 'vitor',
  Julia = 'julia'
}

export const TeamMembers: Record<Team, TeamMember[]> = {
  [Team.Architecture]: [TeamMember.Santiago, TeamMember.Nils],
  [Team.Framework]: [TeamMember.Samuel, TeamMember.Andres, TeamMember.Davide, TeamMember.Vitor],
  [Team.TimeTracking]: [TeamMember.Leonardo, TeamMember.Marek, TeamMember.Ian],
  [Team.WorkManagement]: [TeamMember.Lucas, TeamMember.Annalisa, TeamMember.Marcelo, TeamMember.Marcello],
  [Team.Growth]: [
    TeamMember.Fabian,
    TeamMember.Carolina,
    TeamMember.Leandro,
    TeamMember.Mika,
    TeamMember.Nils,
    TeamMember.Max
  ],
  [Team.Marketing]: [TeamMember.Julia],
  [Team.Design]: [TeamMember.Lucas, TeamMember.Leonardo, TeamMember.Samuel, TeamMember.Carolina]
}
