import { DropdownOption } from '@awork/_shared/models/select-option.model'
import { BadgeColor, Color } from '@awork/_shared/types/color'

/**
 * The project status in awork
 */
export interface IProjectStatusType {
  key: string
  value: string
  order?: number
  colorClass: string
  color?: string
  typeOrder?: number
}

export type ProjectStatusType = 'not-started' | 'progress' | 'closed' | 'stuck'

export class ProjectStatus {
  id: string
  name: string
  type: ProjectStatusType
  order?: number = null
  isPreset: boolean
  typeOrder?: number
  projectId?: string
  projectTemplateId?: string
  createdOn: string
  createdBy: string
  updatedOn: string
  updatedBy: string

  constructor(projectStatus: any) {
    Object.assign(this, projectStatus)
  }

  get urlName(): string {
    return decodeURIComponent(this.name.replace(/ /g, '-'))
  }

  get colorClass(): string {
    const el: IProjectStatusType = ProjectStatus.types.find(x => x.key === this.type)
    if (el) {
      return el.colorClass
    } else {
      return 'aw-2-bg--fog'
    }
  }

  get color(): BadgeColor {
    const el = ProjectStatus.types.find(x => x.key === this.type)
    if (el) {
      return el.colorClass.replace('aw-2-bg--', '') as BadgeColor
    } else {
      return Color.Steel
    }
  }

  get textColor(): string {
    const el = ProjectStatus.types.find(x => x.key === this.type)
    if (el) {
      const cssClass = el.colorClass.replace('aw-2-bg--', 'aw-2-txt--')
      return cssClass.includes('fog') ? 'aw-2-txt--steel' : cssClass
    } else {
      return 'aw-2-txt--fog'
    }
  }

  static get types(): IProjectStatusType[] {
    return [
      {
        key: 'not-started',
        value: q.translations.ProjectStatusModel.notStarted,
        order: 1,
        colorClass: 'aw-2-bg--blue',
        color: Color.Blue
      },
      {
        key: 'progress',
        value: q.translations.ProjectStatusModel.progress,
        order: 2,
        colorClass: 'aw-2-bg--yellow',
        color: Color.Yellow
      },
      {
        key: 'stuck',
        value: q.translations.ProjectStatusModel.stuck,
        order: 3,
        colorClass: 'aw-2-bg--red',
        color: Color.Red
      },
      {
        key: 'closed',
        value: q.translations.ProjectStatusModel.done,
        order: 4,
        colorClass: 'aw-2-bg--green',
        color: Color.Green
      }
    ]
  }

  static get typeOptions(): DropdownOption<ProjectStatusType>[] {
    return ProjectStatus.types.map(
      type =>
        ({
          value: type.key,
          label: type.value,
          dotColor: type.color as Color
        }) as DropdownOption<ProjectStatusType>
    )
  }
}
