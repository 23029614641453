import { Project } from '@awork/features/project/models/project.model'
import { TimeTracking } from '@awork/features/time-tracking/models/time-tracking.model'
import { TaskStatus } from '@awork/features/task/models/task-status.model'
import { ProjectStatus } from '@awork/features/project/models/project-status.model'
import { User } from '@awork/features/user/models/user.model'
import { Task } from '@awork/features/task/models/task.model'
import { Comment, Reaction } from '@awork/_shared/models/comment.model'
import replace from '@awork/_shared/functions/replace'
import { stripHTML } from '@awork/_shared/functions/string-operations'
import { differenceInCalendarDays } from '@awork/_shared/functions/date-fns-wrappers'
import { DatePipe } from '@angular/common'
import getLanguage from '@awork/_shared/functions/get-language'
import { unescape } from '@awork/_shared/functions/lodash'
import { Color } from '@awork/_shared/types/color'
import {
  CONVERSATION_TYPES,
  ConversationType,
  EVENT_NOTIFICATION_TYPES,
  EventNotificationType,
  GroupingType,
  NotificationEvents,
  NotificationType,
  ProjectConversations,
  ProjectGroupType,
  NotificationGroup,
  ValidEntityTypes
} from '@awork/framework/models/notification.types'

export interface INotification {
  id: string
  workspaceId: string
  isNewConversation?: boolean
  userId: string
  type: NotificationType
  event: NotificationEvents
  seen: boolean
  clicked: boolean
  seenOn?: Date | string
  projectId?: string
  project?: Project
  taskId?: string
  task?: Task
  timeTrackingId?: string
  timeTracking?: TimeTracking
  commentId?: string
  comment?: Comment
  reaction?: Reaction
  taskStatusId?: string
  taskStatus?: TaskStatus
  projectStatusId?: string
  projectStatus?: ProjectStatus
  responsibleUserId?: string
  responsibleUser?: User
  userInNotificationId?: string
  userInNotification?: User
  createdOn: Date | string
  visible?: boolean
  notifications?: Notification[]
  groupingType?: GroupingType
  reminderOfNotificationId?: string
  file?: {
    name: string
    entityName: string
    url: string
  }
  currentUser?: User
  expanded?: boolean

  isScheduledTaskSyncSuccessful?: boolean // Only for TaskScheduleSync notifications
}

export class Notification implements INotification {
  id: string
  workspaceId: string
  userId: string
  type: NotificationType
  event: NotificationEvents
  isNewConversation: boolean
  seen: boolean
  seenOn?: Date | string
  clicked: boolean
  projectId?: string
  project?: Project
  taskId?: string
  task?: Task
  timeTrackingId?: string
  timeTracking?: TimeTracking
  commentId?: string
  comment?: Comment
  reaction?: Reaction
  taskStatusId?: string
  taskStatus?: TaskStatus
  projectStatusId?: string
  projectStatus?: ProjectStatus
  responsibleUserId?: string
  responsibleUser?: User
  userInNotificationId?: string
  userInNotification?: User
  createdOn: Date | string
  visible?: boolean
  notifications?: Notification[]
  groupingType?: GroupingType
  reminderOfNotificationId?: string
  file?: {
    name: string
    entityName: string
    url: string
  }
  currentUser?: User
  expanded?: boolean
  uiProperties?: {
    description: string
    groupDescription: string
    text: string
    detail: string
    truncatedProjectName: string
    hide: boolean
  }

  isScheduledTaskSyncSuccessful?: boolean // Only for TaskScheduleSync notifications

  translations = q.translations.Notifications

  constructor(notification: INotification, currentUser?: User) {
    Object.assign(this, notification)

    if (currentUser) {
      this.currentUser = currentUser
    }

    this.mapNotification()
    this.setUIProperties()
  }

  /**
   * Get the ungrouped notifications
   * @param {NotificationGroup[]} notificationGroups
   * @returns {Notification[]}
   */
  static getNotifications(notificationGroups: NotificationGroup[]): Notification[] {
    return notificationGroups.reduce((allNotifications, groupItem) => {
      if (groupItem.groupType === 'default') {
        return groupItem.groups as Notification[]
      }

      return (groupItem.groups as ProjectConversations[]).reduce((allConversations, projectConversations) => {
        return allConversations.concat(projectConversations.notifications)
      }, [] as Notification[])
    }, [] as Notification[])
  }

  /**
   * Maps the properties of the notification
   */
  mapNotification(): void {
    if (this.seenOn) {
      this.seenOn = new Date(this.seenOn)
    }

    if (this.createdOn) {
      this.createdOn = new Date(this.createdOn)
    }

    if (this.project) {
      this.project = Project.mapProject(this.project)

      if (this.task && !this.task.project) {
        this.task.project = this.project
      }
    } else if (this.task?.project) {
      this.project = Project.mapProject(this.task.project)
    }

    if (this.task) {
      this.task = Task.mapTask(this.task)
    }

    if (this.timeTracking) {
      this.timeTracking = new TimeTracking(this.timeTracking)
    }

    if (this.comment) {
      this.comment = new Comment(this.comment)
    }

    if (this.taskStatus) {
      this.taskStatus = new TaskStatus(this.taskStatus)
    }

    if (this.projectStatus) {
      this.projectStatus = new ProjectStatus(this.projectStatus)
    }

    if (this.responsibleUser) {
      this.responsibleUser = new User(this.responsibleUser, 'Notification')
    }

    if (this.userInNotification) {
      this.userInNotification = new User(this.userInNotification, 'Notification')
    }

    // For grouping purposes
    this.notifications = []
  }

  /**
   * Sets the properties used in the UI
   * @private
   */
  setUIProperties(): void {
    this.uiProperties = {
      description: this.description,
      groupDescription: this.groupDescription,
      text: this.text,
      detail: this.detail,
      truncatedProjectName: this.truncatedProjectName,
      hide: false
    }
  }

  /**
   * Gets the icon of the notification based on the event
   * @returns {string}
   */
  get icon(): string {
    switch (this.event) {
      case NotificationEvents.TaskCommented:
      case NotificationEvents.TaskCommentReacted:
      case NotificationEvents.ProjectCommented:
      case NotificationEvents.TaskMentioned:
      case NotificationEvents.ProjectMentioned:
        return 'chat'
      case NotificationEvents.TaskAssigned:
      case NotificationEvents.TaskUnassigned:
      case NotificationEvents.ProjectAssigned:
        return 'person'
      case NotificationEvents.TaskStatusChanged:
      case NotificationEvents.ProjectStatusChanged:
        return 'arrow_forward'
      case NotificationEvents.TaskDue:
      case NotificationEvents.ProjectDue:
      case NotificationEvents.ProjectDueWeekend:
      case NotificationEvents.MonthlyTimeEntriesReport:
      case NotificationEvents.WeeklyTimeEntriesReport:
        return 'alarm'
      case NotificationEvents.Birthday:
        return 'cake'
      case NotificationEvents.ExportAvailable:
        return 'file_download'
      case NotificationEvents.TaskScheduleSyncCompleted:
        return this.isScheduledTaskSyncSuccessful ? 'event' : 'error_outline'
      default:
        return 'notifications'
    }
  }

  /**
   * Gets the description of the notification based on the event
   * @returns {string}
   */
  // eslint-disable-next-line complexity
  get description(): string {
    let userName: string

    if (this.responsibleUser) {
      userName = this.responsibleUser.shortName
    }

    switch (this.event) {
      case NotificationEvents.TaskAssigned:
        return replace(this.translations.taskAssigned.description, { userName })
      case NotificationEvents.TaskUnassigned:
        return replace(this.translations.taskUnassigned.description, { userName })
      case NotificationEvents.TaskStatusChanged:
        return replace(this.translations.taskStatusChanged.description, { userName })
      case NotificationEvents.TaskCommented:
        if (this.task) {
          return replace(this.translations.entityCommented.description, {
            userName,
            entityName: this.truncatedEntityName
          })
        }
        break
      case NotificationEvents.TaskMentioned:
        if (this.task) {
          return replace(this.translations.entityMentioned.description, {
            userName,
            entityName: this.truncatedEntityName
          })
        }
        break
      case NotificationEvents.ProjectAssigned:
        return replace(this.translations.projectAssigned.description, { userName })
      case NotificationEvents.ProjectStatusChanged:
        return replace(this.translations.projectStatusChanged.description, { userName })
      case NotificationEvents.ProjectCommented:
        if (this.project) {
          return replace(this.translations.entityCommented.description, {
            userName,
            entityName: this.truncatedEntityName
          })
        }
        break
      case NotificationEvents.ProjectMentioned:
        if (this.project) {
          return replace(this.translations.entityMentioned.description, {
            userName,
            entityName: this.truncatedEntityName
          })
        }
        break
      case NotificationEvents.ProjectDue:
      case NotificationEvents.ProjectDueWeekend:
        if (this.project) {
          return replace(this.translations.projectDue.description, {
            projectName: this.truncatedEntityName
          })
        }
        break
      case NotificationEvents.TaskDue:
        if (this.task) {
          return replace(this.translations.taskDue.description, {
            taskName: this.truncatedEntityName
          })
        }
        break
      case NotificationEvents.WeeklyTimeEntriesReport:
        return this.translations.missingTimeEntriesWeek.description
      case NotificationEvents.MonthlyTimeEntriesReport:
        return this.translations.missingTimeEntriesMonth.description
      case NotificationEvents.Birthday:
        if (this.userInNotification) {
          return this.userInNotificationId === this.currentUser.id
            ? replace(this.translations.currentUserBirthday.description, {
                emoji: this.getBirthdayEmoji()
              })
            : replace(this.translations.birthday.description, {
                name: this.userInNotification.shortName,
                emoji: this.getBirthdayEmoji()
              })
        }
        break
      case NotificationEvents.ExportAvailable:
        return this.translations.exportAvailable.description
      case NotificationEvents.TaskScheduleSyncCompleted:
        const { successDescription, failureDescription } = this.translations.taskScheduleSync

        return this.isScheduledTaskSyncSuccessful ? successDescription : failureDescription
      case NotificationEvents.CommentReacted:
      case NotificationEvents.TaskCommentReacted:
      case NotificationEvents.ProjectCommentReacted:
        const isOwnComment = this.comment?.createdBy === this.currentUser?.id
        const translation = isOwnComment
          ? this.translations.myCommentReacted.description
          : this.translations.commentReacted.description

        return replace(translation, {
          userName,
          reaction: this.reaction?.emoji
        })
    }
    return null
  }

  /**
   * Gets the description of the group notification based on the grouping type and event
   * @returns {string}
   */
  get groupDescription(): string {
    if (this.groupingType && this.notifications && this.notifications.length > 0) {
      let userName: string

      if (this.responsibleUser) {
        userName = this.responsibleUser.shortName
      }

      switch (this.groupingType) {
        case 'event-entity-user':
          return this.getEventEntityUserDescription(userName, this.truncatedEntityName)
        case 'event-entity':
          return this.getEventEntityDescription(this.truncatedEntityName)
        case 'event-user':
          return this.getEventUserDescription(userName)
        case 'event-reminder':
          return this.getEventReminderDescription()
      }
    }
    return null
  }

  /**
   * Gets the description of the group notification based on the event, entity and the user
   * @param {string} userName - The name of the user
   * @param {string} entityName - The name of the entity
   * @returns {string} - The description
   */
  private getEventEntityUserDescription(userName: string, entityName: string): string {
    switch (this.event) {
      case NotificationEvents.TaskAssigned:
        return replace(this.translations.eventEntityUser.taskAssigned, {
          userName,
          entityName
        })
      case NotificationEvents.TaskUnassigned:
        return replace(this.translations.eventEntityUser.taskUnassigned, {
          userName,
          entityName
        })
      case NotificationEvents.ProjectAssigned:
        return replace(this.translations.eventEntityUser.projectAssigned, {
          userName,
          entityName
        })
      case NotificationEvents.TaskCommented:
        return replace(this.translations.eventEntityUser.taskCommented, {
          userName,
          entityName,
          count: this.notifications.length
        })
      case NotificationEvents.TaskCommentReacted:
        return replace(this.translations.eventEntityUser.taskCommentReacted, {
          userName,
          entityName,
          count: this.notifications.length
        })
      case NotificationEvents.ProjectCommentReacted:
        return replace(this.translations.eventEntityUser.projectCommentReacted, {
          userName,
          entityName,
          count: this.notifications.length
        })
      case NotificationEvents.TaskMentioned:
        return replace(this.translations.eventEntityUser.taskMentioned, {
          userName,
          entityName,
          count: this.notifications.length
        })
      case NotificationEvents.ProjectCommented:
        return replace(this.translations.eventEntityUser.projectCommented, {
          userName,
          entityName,
          count: this.notifications.length
        })
      case NotificationEvents.ProjectMentioned:
        return replace(this.translations.eventEntityUser.projectMentioned, {
          userName,
          entityName,
          count: this.notifications.length
        })
      case NotificationEvents.TaskStatusChanged:
        return replace(this.translations.eventEntityUser.taskStatusChanged, {
          userName,
          entityName
        })
      case NotificationEvents.ProjectStatusChanged:
        return replace(this.translations.eventEntityUser.projectStatusChanged, {
          userName,
          entityName
        })
      case NotificationEvents.ExportAvailable:
        return replace(this.translations.eventEntityUser.exportAvailable, {
          count: this.notifications.length
        })
    }
    return null
  }

  /**
   * Gets the description of the group notification based on the event and the entity
   * @param {string} entityName - The name of the entity
   * @returns {string} - The description
   */
  private getEventEntityDescription(entityName: string): string {
    switch (this.event) {
      case NotificationEvents.TaskAssigned:
        return replace(this.translations.eventEntity.taskAssigned, {
          entityName
        })
      case NotificationEvents.TaskUnassigned:
        return replace(this.translations.eventEntity.taskUnassigned, {
          entityName
        })
      case NotificationEvents.ProjectAssigned:
        return replace(this.translations.eventEntity.projectAssigned, {
          entityName
        })
      case NotificationEvents.TaskCommented:
        return replace(this.translations.eventEntity.taskCommented, {
          entityName,
          count: this.notifications.length
        })
      case NotificationEvents.TaskMentioned:
        return replace(this.translations.eventEntity.taskMentioned, {
          entityName,
          count: this.notifications.length
        })
      case NotificationEvents.ProjectCommented:
        return replace(this.translations.eventEntity.projectCommented, {
          entityName,
          count: this.notifications.length
        })
      case NotificationEvents.ProjectMentioned:
        return replace(this.translations.eventEntity.projectMentioned, {
          entityName,
          count: this.notifications.length
        })
      case NotificationEvents.TaskStatusChanged:
        return replace(this.translations.eventEntity.taskStatusChanged, {
          entityName
        })
      case NotificationEvents.ProjectStatusChanged:
        return replace(this.translations.eventEntity.projectStatusChanged, {
          entityName
        })
      case NotificationEvents.TaskCommentReacted:
        return replace(this.translations.eventEntity.taskCommentReacted, {
          entityName,
          count: this.notifications.length
        })
      case NotificationEvents.ProjectCommentReacted:
        return replace(this.translations.eventEntity.projectCommentReacted, {
          entityName,
          count: this.notifications.length
        })
    }
    return null
  }

  /**
   * Gets the description of the group notification based on the event and the user
   * @param {string} userName - The name of the user
   * @returns {string} - The description
   */
  private getEventUserDescription(userName: string): string {
    switch (this.event) {
      case NotificationEvents.TaskAssigned:
        return replace(this.translations.eventUser.taskAssigned, {
          userName,
          count: this.notifications.length
        })
      case NotificationEvents.TaskUnassigned:
        return replace(this.translations.eventUser.taskUnassigned, {
          userName,
          count: this.notifications.length
        })
      case NotificationEvents.ProjectAssigned:
        return replace(this.translations.eventUser.projectAssigned, {
          userName,
          count: this.notifications.length
        })
      case NotificationEvents.TaskCommented:
        return replace(this.translations.eventUser.taskCommented, {
          userName,
          count: this.notifications.length
        })
      case NotificationEvents.TaskMentioned:
        return replace(this.translations.eventUser.taskMentioned, {
          userName,
          count: this.notifications.length
        })
      case NotificationEvents.ProjectCommented:
        return replace(this.translations.eventUser.projectCommented, {
          userName,
          count: this.notifications.length
        })
      case NotificationEvents.ProjectMentioned:
        return replace(this.translations.eventUser.projectMentioned, {
          userName,
          count: this.notifications.length
        })
      case NotificationEvents.TaskStatusChanged:
        return replace(this.translations.eventUser.taskStatusChanged, {
          userName,
          count: this.notifications.length
        })
      case NotificationEvents.ProjectStatusChanged:
        return replace(this.translations.eventUser.projectStatusChanged, {
          userName,
          count: this.notifications.length
        })
      case NotificationEvents.TaskCommentReacted:
        return replace(this.translations.eventUser.taskCommentReacted, {
          userName,
          count: this.notifications.length
        })
      case NotificationEvents.ProjectCommentReacted:
        return replace(this.translations.eventUser.projectCommentReacted, {
          userName,
          count: this.notifications.length
        })
    }
    return null
  }

  private getEventReminderDescription(): string {
    switch (this.event) {
      case NotificationEvents.ProjectDue:
        return replace(this.translations.reminder.projectDue, {
          count: this.notifications.length
        })
      case NotificationEvents.ProjectDueWeekend:
        return replace(this.translations.reminder.projectDueWeekend, {
          count: this.notifications.length
        })
      case NotificationEvents.TaskDue:
        return replace(this.translations.reminder.taskDue, {
          count: this.notifications.length
        })
      case NotificationEvents.WeeklyTimeEntriesReport:
        return this.translations.missingTimeEntriesWeek.description
      case NotificationEvents.MonthlyTimeEntriesReport:
        return this.translations.missingTimeEntriesMonth.description
      case NotificationEvents.Birthday:
        return replace(this.translations.reminder.birthday, {
          count: this.notifications.length,
          emoji: this.getBirthdayEmoji()
        })
      default:
        return null
    }
  }

  /**
   * Gets the color of the notification based on the event
   * This color is used for the text and icon color
   * @returns {string}
   */
  // eslint-disable-next-line complexity
  get color(): string {
    switch (this.event) {
      case NotificationEvents.TaskAssigned:
      case NotificationEvents.TaskUnassigned:
      case NotificationEvents.ProjectAssigned:
        return 'green'
      case NotificationEvents.TaskStatusChanged:
      case NotificationEvents.ProjectStatusChanged:
        return 'yellow'
      case NotificationEvents.ProjectDue:
      case NotificationEvents.ProjectDueWeekend:
      case NotificationEvents.TaskDue:
      case NotificationEvents.WeeklyDueTasksSummary:
      case NotificationEvents.TimeTrackingReminder:
      case NotificationEvents.MonthlyTimeEntriesReport:
      case NotificationEvents.WeeklyTimeEntriesReport:
        return 'red'
      case NotificationEvents.ExportAvailable:
        return 'steel'
      case NotificationEvents.TaskCommented:
      case NotificationEvents.ProjectCommented:
      case NotificationEvents.TaskMentioned:
      case NotificationEvents.ProjectMentioned:
      case NotificationEvents.TaskCommentReacted:
      case NotificationEvents.ProjectCommentReacted:
      case NotificationEvents.CommentReacted:
      default:
        return 'blue'
    }
  }

  /**
   * Gets the text of the notification based on the event
   * @returns {string}
   */
  get text(): string {
    switch (this.event) {
      case NotificationEvents.TaskAssigned:
      case NotificationEvents.TaskUnassigned:
      case NotificationEvents.TaskStatusChanged:
      case NotificationEvents.TaskDue:
        if (this.task) {
          return this.truncatedEntityName
        }
        break
      case NotificationEvents.ProjectAssigned:
      case NotificationEvents.ProjectStatusChanged:
      case NotificationEvents.ProjectDueWeekend:
        if (this.project) {
          return this.truncatedEntityName
        }
        break
      case NotificationEvents.Birthday:
        return this.groupingType === 'no-group'
          ? this.userInNotificationId === this.currentUser.id
            ? this.translations.currentUserBirthday.text
            : replace(this.translations.birthday.text, { name: this.userInNotification.fullName })
          : this.getBirthdayGroupText()
    }
    return null
  }

  /**
   * Gets the detail of the notification based on the event
   * @returns {string}
   */
  get detail(): string {
    switch (this.event) {
      case NotificationEvents.TaskStatusChanged:
        return replace(this.translations.entityStatusChanged.detail, {
          statusColor: this.taskStatus.color === Color.Fog ? Color.Steel : this.taskStatus.color,
          statusName: this.taskStatus.name
        })
      case NotificationEvents.ProjectStatusChanged:
        return replace(this.translations.entityStatusChanged.detail, {
          statusColor: this.projectStatus.color,
          statusName: this.projectStatus.name
        })
      case NotificationEvents.TaskDue:
        return this.getDueDateDetail(this.task.dueOn)
      case NotificationEvents.ProjectDue:
      case NotificationEvents.ProjectDueWeekend:
        return this.getDueDateDetail(this.project.dueDate)
      case NotificationEvents.TaskCommented:
      case NotificationEvents.TaskCommentReacted:
      case NotificationEvents.ProjectCommented:
      case NotificationEvents.ProjectCommentReacted:
      case NotificationEvents.CommentReacted:
      case NotificationEvents.TaskMentioned:
      case NotificationEvents.ProjectMentioned:
        if (this.comment) {
          return this.truncatedComment
        }
        break
      case NotificationEvents.ExportAvailable:
        return this.translations.exportAvailable.detail
    }
    return null
  }

  /**
   * Gets the entity due date text for the detail of the notification
   * @param {Date | string} dueDate
   * @returns {string}
   */
  private getDueDateDetail(dueDate: Date | string): string {
    const due = new Date(dueDate)
    due.setHours(0)
    due.setMinutes(0)

    const today = new Date()
    today.setHours(0)
    today.setMinutes(0)

    const days = differenceInCalendarDays(due, today)
    const translation = this.translations.entityDue.detail

    switch (true) {
      case days === 0:
        return translation.today
      case days === 1:
        return translation.tomorrow
      case days > 1:
        return replace(translation.days, { days })
      default:
        return replace(translation.past, {
          dueDate: new DatePipe(getLanguage()).transform(dueDate ? new Date(dueDate) : new Date(), 'mediumDate')
        })
    }
  }

  /**
   * Gets the birthday text grouping the name of the users
   * @returns {string}
   */
  private getBirthdayGroupText(): string {
    const names: string[] = []

    this.notifications.forEach(notification => {
      names.push(notification.userInNotification.shortName)
    })

    // Join the names with ',' and replace the last one with 'and'
    let joinedNames = names.join(', ')
    joinedNames =
      joinedNames.slice(0, joinedNames.lastIndexOf(',')) +
      joinedNames.slice(joinedNames.lastIndexOf(',')).replace(',', ` ${q.translations.common.and}`)

    if (!names?.length) {
      return null
    }

    return replace(this.translations.reminder.birthdayGroupText, {
      names: joinedNames
    })
  }

  /**
   * Gets the action text based on the event
   * @returns {string} - The action text
   */
  get actionText(): string {
    switch (this.event) {
      case NotificationEvents.TaskAssigned:
      case NotificationEvents.TaskUnassigned:
      case NotificationEvents.TaskStatusChanged:
      case NotificationEvents.TaskCommented:
      case NotificationEvents.TaskMentioned:
      case NotificationEvents.TaskDue:
      case NotificationEvents.TaskCommentReacted:
        return this.translations.task.actionText
      case NotificationEvents.ProjectAssigned:
      case NotificationEvents.ProjectStatusChanged:
      case NotificationEvents.ProjectCommented:
      case NotificationEvents.ProjectMentioned:
      case NotificationEvents.ProjectDue:
      case NotificationEvents.ProjectDueWeekend:
      case NotificationEvents.ProjectCommentReacted:
        return this.translations.project.actionText
      case NotificationEvents.WeeklyTimeEntriesReport:
      case NotificationEvents.MonthlyTimeEntriesReport:
        return this.translations.timeTracking.actionText
      case NotificationEvents.ExportAvailable:
        return this.translations.exportAvailable.actionText
      case NotificationEvents.Birthday:
        return this.translations.user.actionText
      default:
        return null
    }
  }

  /**
   * Gets the navigation command of the notification based on the event
   * @returns {any[]}
   */
  get navigation(): any[] {
    switch (this.event) {
      case NotificationEvents.TaskAssigned:
      case NotificationEvents.TaskUnassigned:
      case NotificationEvents.TaskStatusChanged:
      case NotificationEvents.TaskCommented:
      case NotificationEvents.TaskCommentReacted:
      case NotificationEvents.TaskMentioned:
      case NotificationEvents.TaskDue:
      case NotificationEvents.TaskPredecessorFinished:
        return ['/tasks', this.taskId, 'details']
      case NotificationEvents.ProjectAssigned:
      case NotificationEvents.ProjectStatusChanged:
      case NotificationEvents.ProjectCommented:
      case NotificationEvents.ProjectCommentReacted:
      case NotificationEvents.ProjectMentioned:
      case NotificationEvents.ProjectDue:
      case NotificationEvents.ProjectDueWeekend:
        return ['/projects', this.projectId, 'details']
      case NotificationEvents.WeeklyTimeEntriesReport:
      case NotificationEvents.MonthlyTimeEntriesReport:
        return ['/time-tracking/my-day']
      case NotificationEvents.Birthday:
        return ['/users', this.userInNotificationId, 'details']
      default:
        return null
    }
  }

  /**
   * Gets the mobile navigation command of the notification based on the event
   * @returns {any[]}
   */
  get mobileNavigation(): any[] {
    switch (this.event) {
      case NotificationEvents.TaskAssigned:
      case NotificationEvents.TaskUnassigned:
      case NotificationEvents.TaskStatusChanged:
      case NotificationEvents.TaskCommented:
      case NotificationEvents.TaskCommentReacted:
      case NotificationEvents.TaskMentioned:
      case NotificationEvents.TaskDue:
        return null // It should open the details modal without navigation
      case NotificationEvents.ProjectAssigned:
      case NotificationEvents.ProjectStatusChanged:
      case NotificationEvents.ProjectCommented:
      case NotificationEvents.ProjectCommentReacted:
      case NotificationEvents.ProjectMentioned:
      case NotificationEvents.ProjectDue:
      case NotificationEvents.ProjectDueWeekend:
        return ['/projects', this.projectId, { showModal: true }]
      case NotificationEvents.WeeklyTimeEntriesReport:
      case NotificationEvents.MonthlyTimeEntriesReport:
        return ['/timer']
      default:
        return null
    }
  }

  /**
   * Gets the entity type of the notification based on the event
   * @returns {ValidEntityTypes}
   */
  get entityType(): ValidEntityTypes {
    switch (this.event) {
      case NotificationEvents.TaskAssigned:
      case NotificationEvents.TaskUnassigned:
      case NotificationEvents.TaskStatusChanged:
      case NotificationEvents.TaskCommented:
      case NotificationEvents.TaskCommentReacted:
      case NotificationEvents.TaskMentioned:
      case NotificationEvents.TaskDue:
        return 'tasks'
      case NotificationEvents.ProjectAssigned:
      case NotificationEvents.ProjectStatusChanged:
      case NotificationEvents.ProjectCommented:
      case NotificationEvents.ProjectCommentReacted:
      case NotificationEvents.ProjectMentioned:
      case NotificationEvents.ProjectDue:
      case NotificationEvents.ProjectDueWeekend:
        return 'projects'
      case NotificationEvents.WeeklyTimeEntriesReport:
      case NotificationEvents.MonthlyTimeEntriesReport:
        return 'timetrackings'
      default:
        return null
    }
  }

  /**
   * Gets the entity name of the notification based on the event
   * @returns {string}
   */
  get entityName(): string {
    switch (this.event) {
      case NotificationEvents.TaskAssigned:
      case NotificationEvents.TaskUnassigned:
      case NotificationEvents.TaskStatusChanged:
      case NotificationEvents.TaskCommented:
      case NotificationEvents.TaskCommentReacted:
      case NotificationEvents.TaskMentioned:
      case NotificationEvents.TaskDue:
        return this.task.name
      case NotificationEvents.ProjectAssigned:
      case NotificationEvents.ProjectStatusChanged:
      case NotificationEvents.ProjectCommented:
      case NotificationEvents.ProjectCommentReacted:
      case NotificationEvents.ProjectMentioned:
      case NotificationEvents.ProjectDue:
      case NotificationEvents.ProjectDueWeekend:
        return this.project.name
      case NotificationEvents.WeeklyTimeEntriesReport:
      case NotificationEvents.MonthlyTimeEntriesReport:
      default:
        return null
    }
  }

  /**
   * Gets the entity Id based on the entity type
   * @returns {string}
   */
  get entityId(): string {
    switch (this.entityType) {
      case 'tasks':
        return this.taskId
      case 'projects':
        return this.projectId
      case 'timetrackings':
        return this.timeTrackingId
    }
  }

  /**
   * Gets the seen state of a group notification
   * @returns {boolean}
   */
  get groupSeen(): boolean {
    return this.notifications.length > 0 ? !this.notifications.some(n => !n.seen) : this.seen
  }

  /**
   * Gets a random emoji for birthday notifications based on the notification id
   * @returns {string}
   */
  private getBirthdayEmoji(): string {
    const emojis = ['🎂', '🎉', '🥂', '🎊', '🍦', '🎈', '🍰']

    return emojis[this.id.charCodeAt(0) % (emojis.length - 1)]
  }

  get truncatedComment(): string {
    if (this.comment) {
      return this.truncateString(unescape(this.comment.formattedMessage), 120)
    }
    return null
  }

  get truncatedEntityName(): string {
    if (this.entityName) {
      return this.truncateString(this.entityName, 40)
    }
    return null
  }

  get truncatedProjectName(): string {
    return this.project ? this.truncateString(this.project.name, 40) : null
  }

  get isConversation(): boolean {
    return CONVERSATION_TYPES.includes(this.event)
  }

  get isEventNotification(): boolean {
    return EVENT_NOTIFICATION_TYPES.includes(this.event)
  }

  get isDefaultNotification(): boolean {
    return ![...CONVERSATION_TYPES, ...EVENT_NOTIFICATION_TYPES].includes(this.event)
  }

  get groupType(): ConversationType | EventNotificationType | ProjectGroupType {
    if (this.isConversation) {
      return this.seen ? 'seen' : 'unseen'
    }

    switch (this.event) {
      case NotificationEvents.ProjectAssigned:
        return 'newProjects'
      case NotificationEvents.TaskAssigned:
        return 'newTasks'
      case NotificationEvents.TaskUnassigned:
        return 'unassignedTasks'
      case NotificationEvents.TaskPredecessorFinished:
        return 'taskPredecessorDone'
      case NotificationEvents.TaskDue:
      case NotificationEvents.ProjectDue:
      case NotificationEvents.ProjectDueWeekend:
        return 'deadlines'
      case NotificationEvents.Birthday:
        return 'birthdays'
      case NotificationEvents.ExportAvailable:
      case NotificationEvents.TaskScheduleSyncCompleted:
      case NotificationEvents.TasksImportedFinished:
        return 'reminders'
      case NotificationEvents.TaskStatusChanged:
        const { baseType } = this.task
        return baseType === 'private' ? 'privateTaskUpdates' : 'projectUpdates'
      case NotificationEvents.ProjectStatusChanged:
        return 'projectUpdates'
      default:
        return null
    }
  }

  /**
   * Truncates the string to a certain length
   * @param {string} string
   * @param {number} length
   * @returns {string}
   */
  truncateString(string: string, length: number): string {
    if (string) {
      const truncatedString = string.length > length ? string.substr(0, length) + '...' : string
      return stripHTML(truncatedString)
    }
    return ''
  }
}
