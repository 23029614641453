import { EntitySignalStore, Mapper } from '@awork/core/state/signal-store/entitySignalStore'
import { computed, Signal } from '@angular/core'
import { distinctUntilChanged, map, Observable } from 'rxjs'
import { areArraysEqual, distinctUntilArrayItemChanged, filterAndMap } from '@awork/core/state/signal-store/helpers'
import { Order, SelectOptions } from '@awork/core/state/signal-store/types'
import { deepEqual } from '@awork/_shared/functions/lodash'

/**
 * Query class for entity signal stores.
 * Provides functions to query the store and select entities.
 *
 * ### Usage example
 * ```ts
 * @Injectable({ providedIn: 'root' })
 * export class UserQuery extends EntitySignalQuery<User> {
 *   constructor(private userStore: UserStore) {
 *     super(userStore)
 *   }
 *
 *   get activeUser(): Signal<User> {
 *     return this.getActive()
 *   }
 * }
 * ```
 */
export class EntitySignalQuery<Entity extends { id: string }> {
  private entitySignalStore: EntitySignalStore<Entity>
  private readonly entityConstructor: Mapper<Entity>

  constructor(store: EntitySignalStore<Entity>) {
    this.entitySignalStore = store
    this.entityConstructor = store.getEntityConstructor()
  }

  /**
   * Maps an entity using the entity constructor
   * @param {Entity} entity
   * @returns {Entity | null}
   */
  mapEntity(entity: Entity): Entity | null {
    return entity ? this.entityConstructor(entity) : null
  }

  /**
   * Maps entities using the entity constructor
   * @param {Entity[]} entities
   * @returns {Entity[]}
   */
  mapEntities(entities: Entity[]): Entity[] {
    return entities.map(entity => this.mapEntity(entity))
  }

  /**
   * Gets the loading state
   * @returns {Signal<boolean>}
   */
  queryLoading(): Signal<boolean> {
    return this.entitySignalStore.getIsLoading()
  }

  /**
   * Selects the loading state
   * @return {Observable<boolean>}
   */
  selectLoading(): Observable<boolean> {
    return this.entitySignalStore.isLoading$
  }

  /**
   * Gets the error state
   * @returns {Signal<Error>}
   */
  queryError(): Signal<Error> {
    return this.entitySignalStore.getError()
  }

  /**
   * Selects the error state
   * @return {Observable<Error>}
   */
  selectError(): Observable<Error> {
    return this.entitySignalStore.error$
  }

  /**
   * Gets an entity by id
   * Optionally maps the entity using the entity constructor
   * @param {string} entityId
   * @returns {Entity}
   */
  private takeEntity(entityId: string): Entity | null {
    return this.entitySignalStore.entityMap()[entityId] || null
  }

  /**
   * Gets an entity by id
   * @param {string} entityId
   * @returns {Signal<Entity>}
   */
  queryEntity(entityId: string): Signal<Entity> {
    return computed(() => this.takeEntity(entityId), { equal: deepEqual })
  }

  /**
   * Selects an entity by id
   * @param {string} entityId
   * @returns {Observable<Entity>}
   */
  selectEntity(entityId: string): Observable<Entity> {
    return this.entitySignalStore.entities$.pipe(
      map(() => this.takeEntity(entityId)),
      distinctUntilChanged(deepEqual)
    )
  }

  /**
   * Gets an entity by id
   * @param {string} entityId
   * @returns {Entity}
   */
  getEntity(entityId: string): Entity {
    return this.takeEntity(entityId)
  }

  /**
   * Gets entities by ids
   * @param {string[]} entityIds
   * @returns {Entity[]}
   */
  private takeMany(entityIds: string[]): Entity[] {
    const entitiesMap = this.entitySignalStore.entityMap()
    const entities: Entity[] = []

    entityIds.forEach(entityId => {
      const entity = entitiesMap[entityId]

      if (entity) {
        entities.push(entity)
      }
    })

    return entities
  }

  /**
   * Gets entities by ids
   * @param {string[]} entityIds
   * @returns {Signal<Entity[]>}
   */
  queryMany(entityIds: string[]): Signal<Entity[]> {
    return computed(
      () => {
        return this.takeMany(entityIds)
      },
      { equal: areArraysEqual }
    )
  }

  /**
   * Selects entities by ids
   * @param {string} entityIds
   * @returns {Observable<Entity[]>}
   */
  selectMany(entityIds: string[]): Observable<Entity[]> {
    return this.entitySignalStore.entitiesMap$.pipe(
      map(() => {
        return this.takeMany(entityIds)
      }),
      distinctUntilArrayItemChanged()
    )
  }

  /**
   * Gets entities all entities.
   * Optionally filters, sorts and limits the result.
   * @param {SelectOptions<Entity>} options
   * @returns {Entity[]}
   */
  private takeAll(options?: SelectOptions<Entity>): Entity[] {
    let entities = this.entitySignalStore.entities()

    const entityConstructor = this.entitySignalStore.getStoreOptions().entityConstructor

    if (options?.filterBy) {
      if (Array.isArray(options.filterBy)) {
        options.filterBy.forEach(filter => {
          entities = filterAndMap(entities, filter, entityConstructor)
        })
      } else {
        entities = filterAndMap(entities, options.filterBy, entityConstructor)
      }
    } else {
      entities = entities.map(entityConstructor)
    }

    if (options?.sortBy) {
      entities = entities.sort((a, b) => {
        if (typeof options.sortBy === 'function') {
          return options.sortBy(a, b)
        } else {
          const order = options.sortByOrder === Order.DESC ? -1 : 1
          return a[options.sortBy] > b[options.sortBy] ? order : a[options.sortBy] < b[options.sortBy] ? -order : 0
        }
      })
    }

    if (options?.limitTo) {
      entities = entities.slice(0, options.limitTo)
    }

    return entities
  }

  /**
   * Gets all entities.
   * Optionally filters, sorts and limits the result.
   * @param {SelectOptions<Entity>} options
   * @returns {Signal<Entity[]>}
   */
  queryAll(options?: SelectOptions<Entity>): Signal<Entity[]> {
    return computed(
      () => {
        return this.takeAll(options)
      },
      { equal: areArraysEqual }
    )
  }

  /**
   * Selects all entities.
   * Optionally filters, sorts and limits the result.
   * @param {SelectOptions<Entity>} options
   * @returns {Observable<Entity[]>}
   */
  selectAll(options?: SelectOptions<Entity>): Observable<Entity[]> {
    return this.entitySignalStore.entities$.pipe(
      map(() => {
        return this.takeAll(options)
      }),
      distinctUntilArrayItemChanged()
    )
  }

  /**
   * Gets all entities
   * Optionally filters, sorts and limits the result.
   * @param {SelectOptions<Entity>} options
   * @returns {Signal<Entity[]>}
   */
  getAll(options?: SelectOptions<Entity>): Entity[] {
    return this.takeAll(options)
  }

  /**
   * Gets the active entity
   * @returns {Signal<Entity>}
   */
  queryActive(): Signal<Entity> {
    return computed(
      () => {
        return this.takeEntity(this.entitySignalStore.getActiveId())
      },
      { equal: deepEqual }
    )
  }

  /**
   * Selects the active entity
   * @returns {Observable<Entity>}
   */
  selectActive(): Observable<Entity> {
    return this.entitySignalStore.entities$.pipe(
      map(() => {
        return this.takeEntity(this.entitySignalStore.getActiveId())
      }),
      distinctUntilChanged(deepEqual)
    )
  }

  /**
   * Gets the active entity
   * @returns {Entity}
   */
  getActive(): Entity {
    return this.takeEntity(this.entitySignalStore.getActiveId())
  }

  /**
   * Gets the count of entities
   * @param {(entity: Entity) => boolean} predicate
   * @returns {number}
   *
   * @example
   *
   * takeCount()
   * takeCount(entity => entity.active)
   */
  private takeCount(predicate?: (entity: Entity) => boolean): number {
    if (predicate) {
      return this.entitySignalStore.entities().filter(predicate).length
    }
    return this.entitySignalStore.ids().length
  }

  /**
   * Gets the count of entities
   * @param {(entity: Entity) => boolean} predicate
   * @returns {Signal<number>}
   *
   * @example
   *
   * queryCount()
   * queryCount(entity => entity.active)
   */
  queryCount(predicate?: (entity: Entity) => boolean): Signal<number> {
    return computed(() => {
      return this.takeCount(predicate)
    })
  }

  /**
   * Selects the count of entities
   * @param {(entity: Entity) => boolean} predicate
   * @returns {Observable<number>}
   *
   * @example
   *
   * selectCount()
   * selectCount(entity => entity.active)
   */
  selectCount(predicate?: (entity: Entity) => boolean): Observable<number> {
    return this.entitySignalStore.entities$.pipe(
      map(() => {
        return this.takeCount(predicate)
      }),
      distinctUntilChanged()
    )
  }

  /**
   * Gets the count of entities
   * @param {(entity: Entity) => boolean} predicate
   * @returns {number}
   *
   * @example
   *
   * getCount()
   * getCount(entity => entity.active)
   */
  getCount(predicate?: (entity: Entity) => boolean): number {
    return this.takeCount(predicate)
  }
}
